import React, { Fragment, useEffect, useState } from "react";
import { getTariff } from "../../../services/businessService";
import LoadingSpinner from "../Backdrop/LoadingSpinner";
import Select from "react-select";

const CustomClearText = () => "clear all";
export const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

export const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "blue" : "black",
});

// export function CustomClearIndicator({
//   ClearIndicator,
//   ClearIndicatorStyles,
//   tariff,
//   selectedOptions,
// }) {
//   return (
//     <Select
//       closeMenuOnSelect={false}
//       components={{ ClearIndicator }}
//       styles={{ clearIndicator: ClearIndicatorStyles }}
//       value={selectedOptions}
//       isMulti
//       options={tariff}
//     />
//   );
// }
