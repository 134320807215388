import * as Yup from "yup";

export const AddLetterSchema = Yup.object().shape({
  title: Yup.string().required("Letter is Required"),
  // content: Yup.string().required("Content is Required"),
});

export const AddBusinessRegistrationSchema = Yup.object().shape({
  message: Yup.string().required("message is Required"),
})

export const AddMessageSchema = Yup.object().shape({
  title: Yup.string().required("Message is Required"),
  message: Yup.string().required("Content is Required"),
});

export const AddClientMessageSchema = Yup.object().shape({
  title: Yup.string().required("Message title  is Required"),
  message: Yup.string().required("Message is Required"),
  // letterId: Yup.string().required("Please select a letter"),
});
