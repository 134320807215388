import http from "../http-common";
import {
  handleLetterRequest,
  createLetterSuccess,
  handleLetterError,
  getLetterSuccess,
} from "../redux/Slices/LetterSlice";
import { toast } from "react-toastify";
import { IdcardFilled } from "@ant-design/icons";

export const generateCertificate = (data) => {
  http
    .post("/certificate/createCertificateBaseOnYear/" + data)
    .then((response) => {
      toast.success(response.data.message);
      return response;
    })
    .catch((error) => {
      console.error(error);
      toast.error(error.response.data.message);
      return error.response;
    });
};

export const getSingleCertificate = async (id) => {
  const data = await http
    .get(`/certificate/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

export const getPublicCertificate = async (id) => {
  const data = await http
    .get(`/certificate/viewCertificate/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};


export const getSingleLetter = async (document_id) => {
  const data = await http
    .get(`/letter/${document_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

export const updateLetter = (data, dispatch, letter_id) => {
  dispatch(handleLetterRequest());
  http
    .put(`/letter/${letter_id}`, data)
    .then((response) => {
      toast.success(response.data.message);
      window.location.reload();
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      dispatch(handleLetterError());
    });
};

export const markAsPaid = (letter_id, comment) => {
  http
    .put(`/certificate/updatePaymentStatus/${letter_id}`, comment)
    .then((response) => {
      toast.success(response.data.message);
      window.location.reload();
    })
    .catch((error) => {
      console.log(error);
      toast.error(error.response.data.message);
    });
};

export const updateSigned = (id, comment) => {
  http
    .put(`/certificate/updateSigned/${id}`, comment)
    .then((response) => {
      console.log(response);
      toast.success(response.data.message);
      window.location.reload();
      return response;
    })
    .catch((error) => {
      console.log(error);
      toast.error(error.response.data.message);
    });
};

// export const readPaymentImage = async (formData) => {
//   const data = await http
//     .post(`/certificate/readCertificate/${formData.id}`, formData, {
//       responseType: "arraybuffer",
//     })
//     .then((response) => {
//       const blob = new Blob([response.data], { type: "application/pdf" });
//       const url = URL.createObjectURL(blob);
//       return url;
//     })
//     .catch((err) => {
//       return err.response;
//     });

//   return data;
// };

export const readPaymentImage = async (formData) => {
  try {
    console.log("form DATA:",formData)
    const response = await http.post(`/certificate/readCertificate/${formData.id}`, formData, {
      responseType: "blob",
    });
    const getFileType = () => {
      const fileName = response.data.type;
        if (fileName?.includes(".jpeg"))
        return "image/jpeg";
      else if(fileName?.includes(".jpg"))
      return "image/jpg"
        else if(fileName?.includes(".png"))
        return "image/png"
        else if(fileName?.includes(".PDF"))
        return "application/pdf"
      else return "application/pdf";
    };

    const blob = new Blob([response.data], { type: getFileType });
    const url = URL.createObjectURL(blob);
    console.log("Bolb url: " ,url)
    return url;
  } catch (error) {
    console.error("Error fetching payment image:", error.response);

    throw error;
  }
};