import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getInstitutionFile } from "../../../../services/FolderService";
import { toast } from "react-toastify";
import "./InstitutionFolders.scss";
import File from "./File";
import LoadingSpinner from "../../Backdrop/LoadingSpinner";
import Breadcrumb from "./breadcrumb";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { addItem } from "../../../../redux/Slices/BreadCrumbSlice";
import { MagnifyingGlassIcon } from "../../../../icons/icons";
import { HistoryOutlined } from "@ant-design/icons";

export const InsitutionFiles = () => {
  const [files, setFiles] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getInstitutionFile(id);
        setFiles(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Failed to fetch folders");
        console.error(error);
        toast.error(error);
      }
    };

    fetchData();
  }, []);

  const handleBack = () => {
    history.goBack();
  };

  const handleSearch = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("q", searchQuery);
    history.push({ search: queryParams.toString() });

    const results = files.filter((item) => {
      // Search in folder names
      if (item.folderName.toLowerCase().includes(searchQuery.toLowerCase())) {
        return true;
      }
      // Search in file names
      if (
        item.documents &&
        item.documents.some((document) =>
          document.filename.toLowerCase().includes(searchQuery.toLowerCase())
        )
      ) {
        return true;
      }
      return false;
    });

    setSearchResults(results);
  };

  const displayedFiles = searchResults?.length > 0 ? searchResults : files;

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="insitution-folder-sub-header">
        <div className="breadcrumb-nav">
          <div className="back-btn" onClick={() => handleBack()}>
            back /
          </div>
          <Breadcrumb />
        </div>
        <div>
          <div className="search-folder">
            <input
              type="search"
              className="search-input"
              value={searchQuery}
              placeholder="Search folder"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div onClick={handleSearch} className="search-icon">
              <MagnifyingGlassIcon />
            </div>
          </div>
        </div>
      </div>
      <div className="all-docs-copy">
        <div className="all-docs-copy__list">
          {displayedFiles.length === 0 ? (
            <div className="void-documents">
              <div className="not-found-text">No documents available</div>
            </div>
          ) : (
            files.map((file) => (
              <div key={file?.id} className="folder">
                <File name={file.filename} file={file} />
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};
