import http from "../http-common";
import {
  handleLetterRequest,
  createLetterSuccess,
  handleLetterError,
  getLetterSuccess,
} from "../redux/Slices/LetterSlice";
import { toast } from "react-toastify";
import {
  getSingleLetterSuccess,
  handleSingleLetterRequest,
} from "../redux/Slices/singleLetterSlice";

export const addLetter = (data, dispatch) => {
  dispatch(handleLetterRequest());

  http
    .post("/letter/", data)
    .then((response) => {
      dispatch(createLetterSuccess());
      toast.success("letter created successfully");
      // window.location.reload();
    })
    .catch((error) => {
      toast.error("Error Creating letter");
      dispatch(handleLetterError());
      console.error(error);
    });
};

export const getLetters = (dispatch) => {
  dispatch(handleLetterRequest());

  http
    .get("/letter/")
    .then((response) => {
      dispatch(getLetterSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleLetterError());
      toast.error(error.response.data.message);
    });
};

export const getSingleLetter = async (document_id) => {
  const data = await http
    .get(`/letter/${document_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

export const getSingleLettersss = async (document_id, dispatch) => {
  dispatch(handleSingleLetterRequest());
  try {
    const response = await http.get(`/letter/${document_id}`);
    console.log(response);
    dispatch(getSingleLetterSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
};

export const letterComment = async () => {
  try {
    const response = await http.get("/letterComment");
    console.log(response.data);
  } catch (error) {
    toast.error(error.data.message);
  }
};

export const CreateLetterComment = async (commentData) => {
  try {
    const response = await http.post("/letterComment", commentData);
    toast.success(response.data.message);
    console.log("DATA:---", response.data);
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const updateLetter = (data,  letter_id) => {
  // dispatch(handleLetterRequest());
  http
    .put(`/letter/${letter_id}`, data)
    .then((response) => {
      toast.success(response.data.message);
      // window.location.reload();
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      // dispatch(handleLetterError());
    });
};

export const approveLetter = async (letter_id) => {
  const data = await http
    .put(`/letter/approve/${letter_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

export const rejectLetter = async (letter_id) => {
  const data = await http
    .put(`/letter/reject/${letter_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  return data;
};
