import React, { useEffect, useState } from "react";
import { Dropdown, Card, Col, Table, Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllLeaseEmailNotification } from "../../../services/leaseService";
import moment from "moment";
import Loading from "../Backdrop/LoadingSpinner";
import Paginate from "react-paginate";
import "../Pagination/pagination.scss";

function LeaseExpiring() {
  const dispatch = useDispatch();

  useEffect(() => {
    getAllLeaseEmailNotification(dispatch);
  }, []);

  const { pending, emailNotificationData } = useSelector(
    (state) => state.lease
  );

  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10;
  let currentData = 0;

  if (Array.isArray(emailNotificationData)) {
    currentData = emailNotificationData.slice(
      currentPage * pageSize,
      (currentPage + 1) * pageSize
    );
  }

  const handlePageChange = (page) => {
    setCurrentPage(page.selected);
  };

  if (pending) {
    return <Loading />;
  }

  return (
    <Card>
      <Card.Header style={{ display: "block !important" }}>
        <div className="row" style={{ width: "100%" }}>
          <Card.Title>Leases Expiring</Card.Title>
        </div>
      </Card.Header>
      <Card.Body>
        <Table responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Property Unit Name</th>
              <th>lease Start Date</th>
              <th>Lease End Date</th>
              <th>Days Left</th>
              {/* <th>Amount Due</th> */}
            </tr>
          </thead>
          <tbody>
            {emailNotificationData && emailNotificationData?.length !== 0 ? (
              currentData?.map((data, index) => {
                return (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <td>{data?.property}</td>
                    <td>
                      {moment(data?.lease_start_date).format("MM/DD/YYYY")}
                    </td>
                    <td>{moment(data?.lease_end_date).format("MM/DD/YYYY")}</td>
                    <td>
                      <Badge variant="primary light">5</Badge>
                    </td>
                    {/* <td className="color-primary">${data.amount}</td> */}
                  </tr>
                );
              })
            ) : (
              <h4 className="mt-3">
                {" "}
                No Lease Expiring within the next 5 days...
              </h4>
            )}
          </tbody>
        </Table>
        {emailNotificationData &&
          Object.keys(emailNotificationData).length >= 10 && (
            <Paginate
              pageCount={emailNotificationData?.length / pageSize}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              previousLabel="<<"
              nextLabel=">>"
              breakLabel="..."
              breakClassName="break-me"
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              activeClassName="active"
            />
          )}
      </Card.Body>
    </Card>
  );
}

export default LeaseExpiring;
