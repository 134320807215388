import http from "../http-common";

import { toast } from "react-toastify";
import {
  getBusinessRegistrationSuccess,
  handleBusinessRegistrationRequest,
} from "../redux/Slices/BusinessRegistrationSlice";

export const getAllBusinessRegistrations = async () => {
  const data = await http
    .get(`/certificate/paidCertificates`)
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return err.response;
    });

  return data;
};

export const getSingleBusiness = async (id, dispatch) => {
  dispatch(handleBusinessRegistrationRequest());
  const data = await http
    .get(`/bussinessRegistration/${id}`)
    .then((response) => {
      dispatch(getBusinessRegistrationSuccess(response.data.data));
      console.log(response);
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

export const getSingleEditBusiness = async (id) => {
  try {
    const res = await http.get(`/bussinessRegistration/${id}`);
    return res.data.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getClientBusinesses = async (id) => {
  const data = await http
    .get(`/bussinessRegistration/clientBusinessForAdmin/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

export const getTariff = async () => {
  const data = await http
    .get("/tarif")
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

export const readBusinessDocument = async (id) => {
  const data = http
    .get(`/bussinessRegistration/readBusinessDocument/${id}`, {
      responseType: "arraybuffer",
    })
    .then((response) => {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      return url;
    })
    .catch((error) => {
      // toast.error("No pdf document");
    });

  return data;
};

export const getNumberOfBusiness = async () => {
  const data = http
    .get("/bussinessRegistration/getNumber/businessRegsitration")
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      console.log(error);
      toast.error("Something went wrong.");
    });

  return data;
};

export const deleteBusinessOnPendingCertificate = async (id) => {
  const data = await http
    .delete(`/bussinessRegistration/${id}`)
    .then((response) => {
      console.log(response);
      toast.success(response.data.message);
      return response;
    })
    .catch((err) => {
      console.log(err);
      toast.error(err.response?.data?.message);
      return err;
    });

  return data;
};
