import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tariffs: [],
  pending: false,
  error: false,
  errorMsg: "",
};

const tariffSlice = createSlice({
  name: "tariffs",
  initialState,
  reducers: {
    handleTariffsRequest: (state) => {
      state.pending = true;
    },
    getTariffsSuccess: (state, action) => {
      state.tariffs = action.payload;
      state.pending = false;
    },
    handleTariffError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
    addTariffSuccess: (state, action) => {
      const newTariff = action.payload;
      state.pending = false;
      state.tariffs = [newTariff, ...state.tariffs];
    },

    deleteTariffSuccess: (state, action) => {
      const deletedTariffId = action.payload;
      state.tariffs = state.tariffs.filter((tariff) => tariff.id !== deletedTariffId);
    },
   
    updateTariffSuccess: (state, action) => {
      const updatedTariff = action.payload;
      const index = state.tariffs.findIndex((tariff) => tariff.id === updatedTariff.id);

      if (index !== -1) {
        state.tariffs[index] = updatedTariff;
      }
    },
   
  },
});

export const {
  handleTariffsRequest,
  getTariffsSuccess,
  addTariffSuccess,
  updateTariffSuccess,
  deleteTariffSuccess
} = tariffSlice.actions;

export default tariffSlice.reducer;
