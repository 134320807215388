import { createSlice } from "@reduxjs/toolkit";

export const procurementSlice = createSlice({
  name: "procurement",
  initialState: {
    procurements: [],
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleProcurementRequest: (state) => {
      state.pending = true;
    },
    createProcurementSuccess: (state) => {
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },
    getProcurementSuccess: (state, action) => {
      state.pending = false;
      state.procurements = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    handleProcurementError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
  },
});

export const {
  handleProcurementRequest,
  createProcurementSuccess,
  getProcurementSuccess,
  handleProcurementError,
} = procurementSlice.actions;
export default procurementSlice.reducer;
