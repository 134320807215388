import http from "../http-common";
import {
  handleProcurementMethodRequest,
  getProcurementMethodSuccess,
  handleProcurementMethodError,
  deleteProcurementForm,
} from "../redux/Slices/ProcurementMethodSlice";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const addProcurementMethod = (data, dispatch, history) => {
  dispatch(handleProcurementMethodRequest());
  http
    .post("/procurementMethod/", data)
    .then((response) => {
      toast.success("Procurement added successfully");
      history.push(`/manage-procurement/`);
    })
    .catch((error) => {
      toast.error("Error adding procurement methods");
      console.error(error);
    });
};

export const getProcurementMethod = (dispatch) => {
  dispatch(handleProcurementMethodRequest());
  http
    .get(`/procurementMethod/`)
    .then((response) => {
      dispatch(getProcurementMethodSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleProcurementMethodError());
      toast.error(error.response.data.message);
    });
};

export const updateProcurementForm = async (data, dispatch, id, history) => {
  dispatch(handleProcurementMethodRequest);
  const result = http
    .put(`/procurement/${id}`, data)
    .then((response) => {
      toast.success(response.data.message);
      return response.data;

      // history.push(`/manage-procurement/`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      dispatch(handleProcurementMethodError);
      return error.response.data;
    });

  return result;
};

export const getSingleProcurementMethod = async (id) => {
  const data = await http
    .get(`/procurementMethod/${id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      return error.response.data;
    });

  return data;
};

export const deleteSingleProcurementMethod = async (id) => {
  Swal.fire({
    title: "Are you sure you want to delete?",
    text: "This action is not revertable",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const data = await http
        .delete(`/procurementMethod/${id}`)
        .then((response) => {
          toast.success(response.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 6000);
          // return response.data.data;
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          // return error.response.data;
        });

      return data;
    }
  });
};
