import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { Dropdown, Card, Col, Table, Badge } from "react-bootstrap";
//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import ReservationStats from "./Dashboard/ReservationStats";
import LatestReview from "./Dashboard/LatestReview";
import RecentBooking from "./Dashboard/RecentBooking";
import BarChart5 from "../charts/Chartjs/bar5";
import TodoList from "./TodoList";
import DateInput from "./DateInput";
import { DateRangePicker } from "react-date-range";
import format from "date-fns/format";
import { addDays, set } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./custom-date-picker.scss";
import moment from "moment";
import { addCheckInCheckOut } from "../../../services/checkInCheckOutService";
import { useDispatch, useSelector } from "react-redux";
import LeaseExpiring from "./LeaseExpiring";
import "./dashboard.scss";
import DashboardPieChart from "./DashboardPieChart";
import { getAllDocumentsCount } from "../../../services/documentsService";
import LoadingSpinner from "../Backdrop/LoadingSpinner";
import { LoadingOutlined } from "@ant-design/icons";
import DailySummary from "./DailySummary";
import io from "socket.io-client";
import { getNumberOfBusiness } from "../../../services/businessService";
import { toast } from "react-toastify";
// import { useRef } from "react";

const options = [
  { value: "aquaview", label: "AquaCiew" },
  { value: "eden", label: "Eden" },
  { value: "vanilla", label: "Vanilla" },
];

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { changeBackground } = useContext(ThemeContext);
  const [businessCounts, setBusinessCounts] = useState({
    businessCount: "0",
    registered: "0",
    pending: "0",
  });

  const fetchBusinessData = async () => {
    try {
      const response = await getNumberOfBusiness();
      if (response.success) {
        const data = {
          totalAmount: response.totalAmount,
          businessCount: response.businessCount,
          registered: response.count.registered,
          pending: response.count.pending,
        };
        setBusinessCounts({ ...data });
      } else {
        toast.error("Error in getting the number of businesses");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
    getAllDocumentsCount(dispatch);
    fetchBusinessData();
    // socketRef.current = io("http://localhost:5000");
    // socketRef.current.on("documentAssigned", (data) => {
    // });
  }, []);

  const { pending, documentCounts } = useSelector((state) => state.documents);
  const { auth } = useSelector((state) => state.auth);

  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ]);

  const [open, setOpen] = useState(false);
  const refOne = useRef(null);
  const socketRef = useRef();

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  useEffect(() => {
    socketRef.current = io("http://localhost:5000");
    socketRef.current.on("documentAssigned", (data) => {
      console.log("data....");
      console.log(data);
    });
  }, []);

  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  function formatAmount(amount) {
    return amount.toLocaleString("en-US");
  }
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-6">
              <h3
                className="card-title"
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                Organizations
              </h3>
              <div
                className="row dashboard-card"
                style={{ justifyContent: "center" }}
              >
                <div className="col-xl-6 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 icon"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                            />
                          </svg>
                        </span>
                        <div className="ms-4">
                          <h2 className="mb-0 font-w600">
                            {pending ? (
                              <LoadingOutlined />
                            ) : documentCounts &&
                              documentCounts?.documentCount !== undefined ? (
                              documentCounts?.documentCount
                            ) : (
                              0
                            )}{" "}
                          </h2>
                          <p className="mb-0 text-nowrap text-dark">Totals</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 icon"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M8.25 9.75h4.875a2.625 2.625 0 010 5.25H12M8.25 9.75L10.5 7.5M8.25 9.75L10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185z"
                            />
                          </svg>
                        </span>
                        <div className="ms-4">
                          <h2 className="mb-0 font-w600">
                            {pending ? (
                              <LoadingOutlined />
                            ) : documentCounts &&
                              documentCounts?.count?.new !== undefined ? (
                              documentCounts?.count?.new
                            ) : (
                              0
                            )}
                          </h2>
                          <p className="mb-0 text-nowrap text-dark">New</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 icon"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
                            />
                          </svg>
                        </span>
                        <div className="ms-4">
                          <h2 className="mb-0 font-w600">
                            {pending ? (
                              <LoadingOutlined />
                            ) : documentCounts &&
                              documentCounts?.count?.assigned !== undefined ? (
                              documentCounts?.count?.assigned
                            ) : (
                              0
                            )}
                          </h2>
                          <p className="mb-0 text-dark">Assigned</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 icon"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4.5 12.75l6 6 9-13.5"
                            />
                          </svg>
                        </span>
                        <div className="ms-4">
                          <h2 className="mb-0 font-w600">
                            {pending ? (
                              <LoadingOutlined />
                            ) : documentCounts &&
                              documentCounts?.count?.approved !== undefined ? (
                              documentCounts?.count?.approved
                            ) : (
                              0
                            )}
                          </h2>
                          <p className="mb-0 text-dark">Approved</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 icon"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </span>
                        <div className="ms-4">
                          <h2 className="mb-0 font-w600">
                            {pending ? (
                              <LoadingOutlined />
                            ) : documentCounts &&
                              documentCounts?.count?.rejected !== undefined ? (
                              documentCounts?.count?.rejected
                            ) : (
                              0
                            )}
                          </h2>
                          <p className="mb-0 text-dark">Rejected</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 icon"
                          >
                            <circle cx="12" cy="12" r="10" />
                            <line x1="8" y1="12" x2="16" y2="12" />
                          </svg>
                        </span>
                        <div className="ms-4">
                          <h2 className="mb-0 font-w600">
                            {pending ? (
                              <LoadingOutlined />
                            ) : documentCounts &&
                              documentCounts?.count?.unassigned !==
                                undefined ? (
                              documentCounts?.count?.unassigned
                            ) : (
                              0
                            )}
                          </h2>
                          <p className="mb-0 text-dark">Unassigned</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Dashboard charts */}
            <div className="col-xl-6">
              <div className="row" style={{ justifyContent: "center" }}>
                {/* <div className="col-xl-6">
                  <div className="card">
                    <div className="card-header border-0 pb-0">
                      <h4 className="fs-20">Recent Booking Schedule</h4>
                    </div>
                    <BarChart5 />
                  </div>
                </div> */}

                <div className="col-xl-12">
                  <div className="card" style={{ borderRadius: "10px" }}>
                    <div className="card-header border-0 pb-0">
                      <h4 className="fs-20">
                        Institution regulatory activity chart Summary
                      </h4>
                    </div>
                    <DashboardPieChart />
                    {/* <PieData /> */}
                  </div>
                </div>
              </div>
            </div>

            {auth?.permissions?.id !== "1" ? (
              <>
                {auth?.permissions?.roles_detail?.permissions.some(
                  (item) => item.permissionName === "business_overview"
                ) && (
                  <div className="col-lg-12 my-6">
                    <h3>Business Overview</h3>
                    {/* <div className="w-100"> */}
                    <div className="w-100 flex flex-wrap flex-lg-nowrap gap-3">
                      <div className="w-100">
                        <div className="card booking">
                          <div className="card-body">
                            <div className="booking-status d-flex align-items-center">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="1em"
                                  viewBox="0 0 320 512"
                                >
                                  <path d="M64 32C28.7 32 0 60.7 0 96V256 416c0 35.3 28.7 64 64 64H192c70.7 0 128-57.3 128-128c0-46.5-24.8-87.3-62-109.7c18.7-22.3 30-51 30-82.3c0-70.7-57.3-128-128-128H64zm96 192H64V96h96c35.3 0 64 28.7 64 64s-28.7 64-64 64zM64 288h96 32c35.3 0 64 28.7 64 64s-28.7 64-64 64H64V288z" />
                                </svg>
                              </span>
                              <div className="ms-4">
                                <h2 className="mb-0 font-w600">
                                  {pending ? (
                                    <LoadingOutlined />
                                  ) : (
                                    documentCounts &&
                                    businessCounts &&
                                    businessCounts?.businessCount
                                  )}
                                </h2>
                                <p className="mb-0 text-nowrap text-dark">
                                  Total Business
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-100">
                        <div className="card booking">
                          <div className="card-body">
                            <div className="booking-status d-flex align-items-center">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="1em"
                                  viewBox="0 0 320 512"
                                >
                                  <path d="M64 32C28.7 32 0 60.7 0 96V256 416c0 35.3 28.7 64 64 64H192c70.7 0 128-57.3 128-128c0-46.5-24.8-87.3-62-109.7c18.7-22.3 30-51 30-82.3c0-70.7-57.3-128-128-128H64zm96 192H64V96h96c35.3 0 64 28.7 64 64s-28.7 64-64 64zM64 288h96 32c35.3 0 64 28.7 64 64s-28.7 64-64 64H64V288z" />
                                </svg>
                              </span>
                              <div className="ms-4">
                                <h2 className="mb-0 font-w600">
                                  {pending ? (
                                    <LoadingOutlined />
                                  ) : (
                                    documentCounts &&
                                    businessCounts &&
                                    businessCounts.totalAmount && (
                                      <>
                                        {formatAmount(
                                          businessCounts?.totalAmount
                                        )}
                                      </>
                                    )
                                  )}
                                </h2>
                                <p className="mb-0 text-nowrap text-dark">
                                  Total Amount Collected
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-100">
                        <div className="card booking">
                          <div className="card-body">
                            <div className="booking-status d-flex align-items-center">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="1em"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM160 152V264v96c0 13.3 10.7 24 24 24s24-10.7 24-24V288h60.9l37.2 81.9c5.5 12.1 19.7 17.4 31.8 11.9s17.4-19.7 11.9-31.8L315.7 275c21.8-14.3 36.3-39 36.3-67c0-44.2-35.8-80-80-80H184c-13.3 0-24 10.7-24 24zm48 88V176h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H208z" />
                                </svg>
                              </span>
                              <div className="ms-4">
                                <h2 className="mb-0 font-w600">
                                  {pending ? (
                                    <LoadingOutlined />
                                  ) : (
                                    businessCounts && businessCounts?.registered
                                  )}
                                </h2>
                                <p className="mb-0 text-nowrap text-dark">
                                  Total Registered
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-100">
                        <div className="card booking">
                          <div className="card-body">
                            <div className="booking-status d-flex align-items-center">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="1em"
                                  viewBox="0 0 320 512"
                                >
                                  <path d="M0 96C0 60.7 28.7 32 64 32h96c88.4 0 160 71.6 160 160s-71.6 160-160 160H64v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V320 96zM64 288h96c53 0 96-43 96-96s-43-96-96-96H64V288z" />
                                </svg>
                              </span>
                              <div className="ms-4">
                                <h2 className="mb-0 font-w600">
                                  {pending ? (
                                    <LoadingOutlined />
                                  ) : (
                                    documentCounts &&
                                    businessCounts &&
                                    businessCounts?.pending
                                  )}
                                </h2>
                                <p className="mb-0 text-nowrap text-dark">
                                  Total Pending
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                )}
                <Col lg={12}>
                  <DailySummary />
                </Col>
              </>
            ) : (
              ""
            )}

            {/* <div className="col-xl-12">
              <div className="card">
                <div
                  className="row"
                  style={{
                    justifyContent: "center",
                    margin: "30px 10px 10px 10px",
                  }}
                >
                  <div
                    className="col-xl-6 col-sm-6"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card" style={{ background: "#F8F8F8" }}>
                      <div className="card-body" style={{ display: "flex" }}>
                        <h3
                          className="card-title text-center"
                          style={{ margin: "auto" }}
                        >
                          HR
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-xl-6 col-sm-12"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card" style={{ background: "#F8F8F8" }}>
                      <div className="card-body" style={{ display: "flex" }}>
                        <h3
                          className="card-title text-center"
                          style={{ margin: "auto" }}
                        >
                          IT Support
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-6 col-sm-6"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card" style={{ background: "#F8F8F8" }}>
                      <div className="card-body" style={{ display: "flex" }}>
                        <h3
                          className="card-title text-center"
                          style={{ margin: "auto" }}
                        >
                          SPU
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-6 col-sm-6"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card" style={{ background: "#F8F8F8" }}>
                      <div className="card-body" style={{ display: "flex" }}>
                        <h3
                          className="card-title text-center"
                          style={{ margin: "auto" }}
                        >
                          Expense
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "block" }}>
                    <div className="px-2 pt-2" style={{ display: "flex" }}>
                      <h4
                        className="text-muted mb-0 mr-2"
                        style={{ marginRight: "5px", lineHeight: "1.4" }}
                      >
                        HEAD OF DEPARTMENT:
                      </h4>
                      <p>DAVID LADIPO</p>
                    </div>
                    <div className="px-2 pt-2" style={{ display: "flex" }}>
                      <h4
                        className="text-muted mb-0"
                        style={{ marginRight: "5px", lineHeight: "1.4" }}
                      >
                        TEL NUMBER:{" "}
                      </h4>
                      <p>+220 3454345</p>
                    </div>
                    <div className="px-2 pt-2" style={{ display: "flex" }}>
                      <h4
                        className="text-muted mb-0"
                        style={{ marginRight: "5px", lineHeight: "1.4" }}
                      >
                        EMAIL:{" "}
                      </h4>
                      <p>dlad@zigtech.net</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="row" style={{ alignItems: "center" }}>
                    <div className="col-lg-3">
                      <Select
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                        style={{
                          lineHeight: "40px",
                          color: "#7e7e7e",
                          paddingLeft: " 15px",
                        }}
                      />
                    </div>
                    <div className="col-lg-3">
                      <div className="calendarWrap">
                        <input
                          value={`${format(
                            range[0].startDate,
                            "MM/dd/yyyy"
                          )}  to  ${format(range[0].endDate, "MM/dd/yyyy")}`}
                          readOnly
                          className="inputBox"
                          onClick={() => setOpen((open) => !open)}
                        />

                        <div ref={refOne}>
                          {open && (
                            <DateRangePicker
                              onChange={(item) => setRange([item.selection])}
                              editableDateInputs={true}
                              moveRangeOnFirstSelection={false}
                              ranges={range}
                              months={2}
                              direction="horizontal"
                              className="calendarElement"
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <Dropdown style={{ position: "relative" }}>
                        <Dropdown.Toggle
                          variant="outline-secondary"
                          size="sm"
                          className="mt-1 mb-2"
                        >
                          Properties: 0 - Room : 0 - Dining Halls
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ with: "100%" }}>
                          <Dropdown.Item href="#">Last 1 hour</Dropdown.Item>
                          <Dropdown.Item href="#">Last 2 hour</Dropdown.Item>
                          <Dropdown.Item href="#">Last 3 hour</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div
                      className="col-lg-3"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        style={{ border: 0, background: "transparent" }}
                      >
                        <i
                          className="fas fa-search"
                          style={{ fontSize: "20px", cursor: "pointer" }}
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-6">
                  <div className="card">
                    <div className="card-header border-0 pb-0">
                      <h4 className="fs-20">Recent Booking Schedule</h4>
                    </div>
                    <RecentBooking />
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="row">
                    <div className="col-xl-12">
                      <ReservationStats />
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <div className="card bg-secondary">
                        <div className="card-body">
                          <div className="d-flex align-items-end pb-4 justify-content-between">
                            <span className="fs-14 font-w500 text-white">
                              Available Room Today
                            </span>
                            <span className="fs-20 font-w600 text-white">
                              <span className="pe-2"></span>683
                            </span>
                          </div>
                          <div className="progress default-progress h-auto">
                            <div
                              className="progress-bar bg-white progress-animated"
                              style={{ width: "60%", height: "13px" }}
                            >
                              <span className="sr-only">60% Complete</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <div className="card bg-secondary">
                        <div className="card-body">
                          <div className="d-flex align-items-end pb-4 justify-content-between">
                            <span className="fs-14 font-w500 text-white">
                              Sold Out Room Today
                            </span>
                            <span className="fs-20 font-w600 text-white">
                              <span className="pe-2"></span>156
                            </span>
                          </div>
                          <div className="progress default-progress h-auto">
                            <div
                              className="progress-bar bg-white progress-animated"
                              style={{ width: "30%", height: "13px" }}
                            >
                              <span className="sr-only">30% Complete</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-xl-3 col-sm-3 col-6 mb-4 col-xxl-6">
                              <div className="text-center">
                                <h3 className="fs-28 font-w600">569</h3>
                                <span className="fs-16">Total Concierge</span>
                              </div>
                            </div>
                            <div className="col-xl-3 col-sm-3 col-6 mb-4 col-xxl-6">
                              <div className="text-center">
                                <h3 className="fs-28 font-w600">2,342</h3>
                                <span className="fs-16">Total Customer</span>
                              </div>
                            </div>
                            <div className="col-xl-3 col-sm-3 col-6 mb-4 col-xxl-6">
                              <div className="text-center">
                                <h3 className="fs-28 font-w600">992</h3>
                                <span className="fs-16">Total Room</span>
                              </div>
                            </div>
                            <div className="col-xl-3 col-sm-3 col-6 mb-4 col-xxl-6">
                              <div className="text-center">
                                <h3 className="fs-28 font-w600">76k</h3>
                                <span className="fs-16 wspace-no">
                                  Total Transaction
                                </span>
                              </div>
                            </div>
                            <div className="mb-5 mt-4 d-flex align-items-center">
                              <div>
                                <h4>
                                  <Link to={"#"} className="text-secondary">
                                    Let Travl Generate Your Annualy Report
                                    Easily
                                  </Link>
                                </h4>
                                <span className="fs-12">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit, sed do eiusmod tempor
                                  incididunt ut labo
                                </span>
                              </div>
                              <div>
                                <Link to={"#"} className="ms-5">
                                  <i className="fas fa-arrow-right fs-20"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* Daily summary section */}

            {/*  */}
            <Col lg={12}>{/* <LeaseExpiring /> */}</Col>
            {/* Revenue section */}
            {/* <Col xl={12} lg={12}>
              <Card>
                <Card.Header>
                  <div className="row">
                    <div className="col-lg-6">
                      <h4 className="card-title">Revenue / Expense Summary</h4>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="property">Select Property</label>
                        <Select
                          defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={options}
                          style={{
                            lineHeight: "40px",
                            color: "#7e7e7e",
                            paddingLeft: " 15px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <BarChart5 />
                </Card.Body>
              </Card>
            </Col> */}
            {/* <div className="col-xl-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="fs-20">Latest Review by Customers</h4>
                </div>
                <div className="card-body pt-0">
                  <LatestReview />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
