import React from "react";

const NavigationBreadCrumb = ({
  path,
  setPath,
  setShowYearFolders,
  setShowYearCategories,
  setShowCategoryFolders,
  setShowProjectDocuments,
  setShowSupplierDocuments,
}) => {
  const handleWhereToShow = (idx) => {
    if (idx === 0) {
      setShowYearFolders(true);
      setShowYearCategories(false);
      setShowCategoryFolders(false);
      setShowProjectDocuments(false);
      setShowSupplierDocuments(false);
    } else if (idx === 1) {
      setShowYearFolders(false);
      setShowYearCategories(true);
      setShowCategoryFolders(false);
      setShowProjectDocuments(false);
      setShowSupplierDocuments(false);
    } else if (idx === 2) {
      setShowYearFolders(false);
      setShowYearCategories(false);
      setShowCategoryFolders(true);
      setShowProjectDocuments(false);
      setShowSupplierDocuments(false);
    } else if (idx === 3) {
      setShowYearFolders(false);
      setShowYearCategories(false);
      setShowCategoryFolders(false);
      setShowProjectDocuments(true);
      setShowSupplierDocuments(false);
    } else {
      setShowYearFolders(false);
      setShowYearCategories(false);
      setShowCategoryFolders(false);
      setShowProjectDocuments(false);
      setShowSupplierDocuments(true);
    }
  };
  return (
    <div className="flex items-center gap-2 my-3">
      {path?.map((crum, idx) => (
        <>
          <button
            className={`text-black outline-none border-none bg-transparent  text-lg`}
            disabled={idx === path?.length - 1}
            onClick={() => {
              setPath(path?.slice(0, idx + 1));
              handleWhereToShow(idx);
            }}
          >
            {crum}
          </button>
          {idx !== path?.length - 1 && <span>/</span>}
        </>
      ))}
    </div>
  );
};

export default NavigationBreadCrumb;
