import { createSlice } from "@reduxjs/toolkit";

export const clientManagementSlice = createSlice({
  name: "Client Management",
  initialState: {
    clients: [],
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleClientManagementRequest: (state) => {
      state.pending = true;
    },
    createClientManagementSuccess: (state) => {
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },
    getClientManagementSuccess: (state, action) => {
      state.pending = false;
      state.clients = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    handleClientManagementError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
   
  },
});

export const {
  handleClientManagementRequest,
  createClientManagementSuccess,
  getClientManagementSuccess,
  handleClientManagementError,
} = clientManagementSlice.actions;
export default clientManagementSlice.reducer;
