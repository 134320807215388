import { createSlice } from "@reduxjs/toolkit";

export const checkInCheckOutSlice = createSlice({
  name: "check in check out",
  initialState: {
    checkInCheckOuts: [],
    saveData: null,
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleCheckInCheckOutRequest: (state) => {
      state.pending = true;
    },
    createCheckInCheckOutSuccess: (state) => {
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },
    getCheckInCheckOutSuccess: (state, action) => {
      state.pending = false;
      state.checkInCheckOuts = action.payload;
      state.saveData = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    handleCheckInCheckOutError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.saveData = null;
      state.errorMsg = action.payload;
    }
  },
});

export const {
  handleCheckInCheckOutRequest,
  createCheckInCheckOutSuccess,
  getCheckInCheckOutSuccess,
  handleCheckInCheckOutError,
} = checkInCheckOutSlice.actions;
export default checkInCheckOutSlice.reducer;
