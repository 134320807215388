import * as Yup from "yup";

export const RegisterBusinessSchema = Yup.object().shape({
    nameOfBussiness: Yup.string().required("Name of business is Required"),
    address: Yup.string().required("Address is Required"),
    city: Yup.string().required("City is required"),
    country: Yup.string().required("Country is required"),
    name: Yup.string().required("Name is required"),
    title: Yup.string().required("Title is required"),
    email: Yup.string().email().required("Email is required"),
    telephone: Yup.string().required("Telephone is required"),
});

