import React from "react";
import { useHistory } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa6";

const BackBtn = () => {
  const history = useHistory();

  const handleClick = () => {
    history.goBack();
  };
  return (
    <div className="mb-0">
      <button className="p-2 bg-green-600 text-white text-decoration-none flex items-center gap-2 w-20 block hover:bg-green-500 outline-none border-none mb-0" onClick={handleClick}>
        <FaChevronLeft />
        <span className="mb-0">Back</span>
      </button>
    </div>
  );
};

export default BackBtn;
