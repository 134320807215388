import React, { useEffect } from "react";
import { Dropdown, Card, Col, Table, Badge } from "react-bootstrap";
import folderIcon from "../../../images/svg/folder.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssignedDocuments,
  getComplianceAssignedDocuments,
} from "../../../services/documentsService";
import { Link } from "react-router-dom";
import io from "socket.io-client";
import { useRef } from "react";
import { addAssignedDocuments } from "../../../redux/Slices/DocumentSlice";
import useSocket from "../../hooks/useSocket";

export default function DailySummary() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const socketRef = useRef();

  useEffect(() => {
    getAssignedDocuments(dispatch);
  }, []);

  const { pending, assignedDocuments } = useSelector(
    (state) => state.documents
  );

  useSocket();

  return (
    <Card>
      <Card.Header style={{ display: "block !important" }}>
        <Card.Title>Daily Summary</Card.Title>
      </Card.Header>
      <Card.Body>
        <div
          className="table-responsive"
          style={{ scrollbarColor: "blue !important" }}
        >
          <div id="job_data" className="dataTables_wrapper">
            <table
              className="display w-100 dataTable "
              id="example5"
              role="grid"
              aria-describedby="example5_info"
            >
              <thead>
                <tr role="row" className="text-dark">
                  <th className="width80">#</th>
                  <th className="sorting_asc text-dark">TR Number</th>
                  <th className="sorting">Document Title</th>
                  <th className="sorting">Institution</th>
                  <th className="sorting">Category</th>
                  <th className="sorting">Status</th>
                  <th className="sorting">Assigned By</th>
                  <th className="sorting">Action</th>
                </tr>
              </thead>

              <tbody>
                {assignedDocuments && assignedDocuments?.length >= 1 ? (
                  assignedDocuments?.map((document, index) => {
                    return (
                      <tr className="text-dark" key={index}>
                        <img src={folderIcon} style={{ height: 40 }} alt="" />
                        <td>
                          {document.TRNo !== null ? document.TRNo : "N/A"}
                        </td>
                        <td>
                          {document.documentTitle !== null
                            ? document.documentTitle
                            : "N/A"}
                        </td>
                        <td>
                          {document.institution !== null
                            ? document.institution
                            : "N/A"}
                        </td>
                        <td>
                          {document.category !== null
                            ? document.category
                            : "N/A"}
                        </td>
                        <td>
                          {document.status === "approved" ? (
                            <button className="btn btn-success btn-xs">
                              {document.status}
                            </button>
                          ) : document.status === "rejected" ? (
                            <button className="btn btn-primary btn-xs">
                              {document.status}
                            </button>
                          ) : document.status === "assigned" ? (
                            <button className="btn btn-secondary btn-xs text-white">
                              {document.status}
                            </button>
                          ) : document.status === "new" ? (
                            <button className="btn btn-info btn-xs text-white">
                              {document.status}
                            </button>
                          ) : (
                            <button className="btn btn-primary btn-xs text-white">
                              {document.status}
                            </button>
                          )}
                        </td>

                        <td>
                          {document.assignByUser === null ||
                          document.assignByUser === undefined
                            ? "Not Assigned"
                            : `${document.assignByUser.firstName} ${document.assignByUser.middleName} ${document.assignByUser.lastName}`}
                        </td>

                        {/* <td>
                          {document.userAssigned === null ||
                          document.userAssigned === undefined
                            ? "Not Assigned"
                            : `${document.userAssigned.firstName} ${document.userAssigned.middleName} ${document.userAssigned.lastName}`}
                        </td> */}
                        <td>
                          <Link
                            to={`/view-document-details/${document.id}`}
                            className="btn shadow btn-xs text-white sharp me-1"
                            style={{ background: "#4D06A5" }}
                          >
                            <i className="fas fa-eye"></i>
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="text-dark">
                    <td>
                      <strong>No Daily Summary</strong>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div style={{ display: "block" }}>
          <div className="px-2 pt-2" style={{ display: "flex" }}>
            <h4
              className="text-muted mb-0 mr-2"
              style={{ marginRight: "5px", lineHeight: "1.4" }}
            >
              Name:
            </h4>
            <p>Firstname Lastname</p>
          </div>

          <div className="px-2 pt-2" style={{ display: "flex" }}>
            <h4
              className="text-muted mb-0"
              style={{ marginRight: "5px", lineHeight: "1.4" }}
            >
              Tel:{" "}
            </h4>
            <p>303003030</p>
          </div>
        </div> */}
      </Card.Body>
    </Card>
  );
}
