import React, { useEffect, useState, useRef } from "react";
import "../components/Certificate/certificate.css";
import logoGppa from "../../images/avatar/logo.jpeg";
import { useParams } from "react-router-dom";
import { getPublicCertificate } from "../../services/certificate";
// import LoadingSpinner from "../Backdrop/LoadingSpinner";
import QRCode from 'qrcode'
import "./ViewCertificate.scss"
// react-pdf-viewer/print
import { useReactToPrint } from 'react-to-print';
import moment from "moment";


export const  ViewCertificate = () =>  {
  
  const { id } = useParams();
  const [certificate, setCertificate] = useState([]);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [qrcodeUrl, setQrcodeUrl] = useState('');

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const fetchCertificate = async () => {
      try {
        setLoading(true);
        const response = await getPublicCertificate(id);
        setCertificate(response.data);
        console.log(certificate)
        setImage(response.directorSign);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchCertificate();
  }, []);

  // generate QRcode as the current url
  useEffect(() => {
    const generateQRCode = async () => {
      const currentUrl = window.location.href;

      try {
        const qrcodeUrl = await QRCode.toDataURL(currentUrl);
        setQrcodeUrl(qrcodeUrl);
      } catch (err) {
        console.error(err);
      }
    };

    generateQRCode();
  }, []);

//   if (loading) {
//     return <LoadingSpinner />;
//   }

  //print document


  
    return (
    <div className="certificate-main"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        // filter: `${certificate?.signed === '0' && 'blur(5px)'}`,
      }}
    >

      <div ref={componentRef} className="view-certificate" style={{marginTop: "50px"}}>
          <div className="certificate-frame-outline"></div>
          <div className="certificate-frame-inner-outline"></div>

          <div className="pm-certificate-border col-xs-12">
            <div className="row pm-certificate-header">
              <div className="pm-certificate-title cursive col-xs-12 text-center">
                <img src={logoGppa} width={200} alt="" />
                <h5 className="gppa-title">Value for Money in Public procurement</h5>
              </div>
            </div>

            <div className="row pm-certificate-body">
              <div className="pm-certificate-block">
                <div className="col-xs-12">
                  <div className="row">
                    <div className="col-xs-2"></div>
                    <div className="pm-certificate-name  margin-0 col-xs-8 text-center">
                      <span className="certificate-title">
                        Certificate of Supplier / Contractor Registration
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12">
                  <div className="row">
                    <div className="col-xs-2"></div>
                    <div className="pm-earned col-xs-8 text-center">
                      <span className="pm-earned-text padding-0 block cursive">
                        {/* {certificate?.bussinessCertificate?.nameOfBusiness} */}
                      </span>
                    </div>
                    <div className="col-xs-2"></div>
                    <div className="col-xs-12"></div>
                  </div>
                </div>

                <div className="col-xs-12">
                  <div className="row">
                    <div className="col-xs-2"></div>
                    <div className="pm-course-title col-xs-8 text-center">
                      <span className="pm-earned-text block cursive">
                        is registered with the Gambia Public Procurement Authority for the year {certificate?.year}
                      </span>
                    </div>
                    <div className="col-xs-2"></div>
                  </div>
                </div>

                <div className="col-xs-12">
                  <div className="row">
                    <div className="col-xs-2"></div>
                    <div className="pm-course-title  col-xs-8 text-center">
                    <div className="gppa-sub-title"> Nature of Business:</div>
                      <span className="pm-credits-text block bold sans">

                        {/* {certificate?.length !== 0
                          ? JSON?.parse(certificate?.bussinessCertificate?.natureOfBusiness).map((b) => b)
                          : ''} */}
                      </span>
                    </div>
                    <div className="pm-course-title col-xs-8 text-center">
                      <span className="pm-earned-text block cursive">This certificate is valid until the {moment(certificate?.expiryDate).format("Do MMMM, YYYY")}</span>
                    </div>
                    <div className="pm-course-title col-xs-8 text-center" style={{ marginBottom: '35px' }}>
                      <span className="pm-earned-text block cursive">Given under my hand this {moment(certificate?.signedDate).format("Do MMMM, YYYY")}</span>
                    </div>

                    <div className="pm-course-title col-xs-8 text-center">
                      <div className="certificate-footer">
                        <div className="certificate-siginature">
                          <div className="barcode" style={{backgroundColor: "red"}}>
                          <img className="barcode-img" src={image} alt="Director Signature" />
                          </div>
                          <div className="pm-course-title underline col-xs-8 text-center">
                            <span className="pm-credits-text block bold sans"></span>
                          </div>
                          <span className="certificate-label-id">DIRECTOR GENERAL</span>
                        </div>
                        <div className="certificate-id">
                          <div className="barcode">
                            <img className="barcode-img" src={qrcodeUrl} alt="barcode" />
                          </div>

                          <span className="certificate-label-id">CERTIFICATE NUMBER</span>
                          <span className="id-data">{certificate?.id}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      {/* <button style={{ marginTop: '20px' }} className="btn btn-secondary btn-sm text-white" onClick={handlePrint}>
        Print
      </button> */}

    </div>
  );
}


