import React from "react";
import ChatBody from "./ChatBody/ChatBody";

const Chat = () => {
  return (
    <div className="row">
      <div className="col-xl-12 col-lg-12 overflow-hidden">
        <ChatBody />
      </div>
    </div>
  );
};

export default Chat;
