import Folder from "./Folder";
import { FileIcon } from "./icons/icons";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { getSingleInstitionFolders } from "../../../../services/FolderService";
import { toast } from "react-toastify";
import "./All-documentsCopy.scss";
import LoadingSpinner from "../../Backdrop/LoadingSpinner";
import { MagnifyingGlassIcon } from "../../../../icons/icons";
import { addItem } from "../../../../redux/Slices/BreadCrumbSlice";
import { useDispatch } from "react-redux";
import Breadcrumb from "./breadcrumb";
import "./institution-folder.scss";
import http from "../../../../http-common";
import LoadingComponent from "../../generic/LoadingComponent";
import NavigationBreadCrumb from "./NavigationBreadCrumb";
import BackBtn from "../../generic/BackBtn";
import FolderIcon from "../../../../icons/folder-96.png";
import DocxIcon from "../../../../icons/docx-96.png";
import XlsxIcon from "../../../../icons/xlsx-96.png";
import PDFIcon from "../../../../icons/pdf-96.png";
import CSVIcon from "../../../../icons/csv-96.png";
import ImageIcon from "../../../../icons/image-96.png";
export const InstitutionFolder = () => {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [yearFolders, setYearFolders] = useState([]);
  const [showYearFolders, setShowYearFolders] = useState(true);
  const [yearCategories, setYearCategories] = useState([]);
  const [showYearCategories, setShowYearCategories] = useState(true);
  const [categoryFolders, setCategoryFolders] = useState([]);
  const [showCategoryFolders, setShowCategoryFolders] = useState(true);
  const [projectDocumentFolders, setProjectDocumentFolders] = useState([]);
  const [showProjectDocuments, setShowProjectDocuments] = useState(false);
  const [supploerDocumentFolders, setSupploerDocumentFolders] = useState([]);
  const [showSupplierDocuments, setShowSupplierDocuments] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [path, setPath] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await getSingleInstitionFolders(id);
        setPath([data?.name]);
        setYearFolders(data.folderYears);
      } catch (error) {
        toast.error("Failed to fetch folders");
        console.error(error);
        toast.error(error.message);
      }
      setLoading(false);
    };

    fetchData();
  }, [id]);

  const getFileSource = (file) => {
    if (file.fileName.includes(".doc")) return <img src={DocxIcon} alt="" />;
    else if (file.fileName.includes(".xls") || file.fileName.includes(".xlsx"))
      return <img src={XlsxIcon} alt="" />;
    else if (file.fileName.includes(".pdf"))
      return <img src={PDFIcon} alt="" />;
    else if (file.fileName.includes(".csv"))
      return <img src={CSVIcon} alt="" />;
    else if (file.type === "folder") return <img src={FolderIcon} alt="" />;
    else return <img src={ImageIcon} alt="" />;
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const fetchCategoryFilesAndFolders = async (folderId) => {
    setIsLoading(true);
    try {
      const { data } = await http.get(
        `/files/getFilesForACategory/${folderId}`
      );
      setCategoryFolders(data.data.projectDocumentFolders);
      setShowCategoryFolders(true);
    } catch (err) {
      toast.error(
        err.response?.data?.message || "Cannot fetch category files and folders"
      );
    }
    setIsLoading(false);
  };

  const NoFIleOrFolders = () => (
    <div className="py-2 text-nowrap">
      <div className="font-bold text-lg">No files or folders found</div>
    </div>
  );

  return (
    <>
      <div className="flex items-center gap-3">
        <BackBtn />
        <NavigationBreadCrumb
          path={path}
          setPath={setPath}
          setShowYearFolders={setShowYearFolders}
          setShowYearCategories={setShowYearCategories}
          setShowCategoryFolders={setShowCategoryFolders}
          setShowProjectDocuments={setShowProjectDocuments}
          setShowSupplierDocuments={setShowSupplierDocuments}
        />
      </div>

      <div className="all-docs-copy mt-3">
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <div className="all-docs-copy__list">
            {showYearFolders ? (
              yearFolders?.length === 0 ? (
                <NoFIleOrFolders />
              ) : (
                yearFolders?.map((folder) => (
                  <div
                    key={folder.id}
                    style={{
                      width: 100,
                    }}
                    className=" text-black text-decoration-none text-wrap cursor-pointer"
                    onClick={() => {
                      setPath([...path, folder.year]);
                      setYearCategories(folder.categoryFolders);
                      setShowYearFolders(false);
                      setShowYearCategories(true);
                    }}
                  >
                    <img
                      className="w-full object-scale-down block mx-auto"
                      src={FolderIcon}
                      alt=""
                    />
                    <p className="break-words text-sm text-center">
                      {folder.year}
                    </p>
                  </div>
                ))
              )
            ) : showYearCategories ? (
              yearCategories?.length === 0 ? (
                <NoFIleOrFolders />
              ) : (
                yearCategories?.map((folder) => (
                  <div
                    key={folder.id}
                    style={{
                      width: 100,
                    }}
                    className=" text-black text-decoration-none text-wrap cursor-pointer"
                    onClick={() => {
                      setPath([...path, folder.name]);
                      setShowYearCategories(false);
                      fetchCategoryFilesAndFolders(folder.id);
                    }}
                  >
                    <img
                      className="w-full object-scale-down block mx-auto"
                      src={FolderIcon}
                      alt=""
                    />
                    <p className="break-words text-sm text-center">
                      {folder.name}
                    </p>
                  </div>
                ))
              )
            ) : showCategoryFolders ? (
              categoryFolders?.length === 0 ? (
                <NoFIleOrFolders />
              ) : (
                categoryFolders.map((folder) => (
                  <div
                    key={folder.id}
                    style={{
                      width: 100,
                    }}
                    className=" text-black text-decoration-none text-wrap cursor-pointer"
                    onClick={() => {
                      setPath([...path, folder.documentTitle]);
                      setProjectDocumentFolders(folder.files);
                      setShowCategoryFolders(false);
                      setShowProjectDocuments(true);
                    }}
                  >
                    {/* <Folder folderName={folder.documentTitle} /> */}
                    <img
                      className="w-full object-scale-down block mx-auto"
                      src={FolderIcon}
                      alt=""
                    />
                    <p className="break-words text-sm text-center">
                      {folder.documentTitle}
                    </p>
                  </div>
                ))
              )
            ) : showProjectDocuments ? (
              projectDocumentFolders?.length === 0 ? (
                <NoFIleOrFolders />
              ) : (
                projectDocumentFolders?.map((file) =>
                  file.type === "file" ? (
                    <div
                      key={file.id}
                      className="flex flex-col items-center gap-2 cursor-pointer"
                      onClick={() => {
                        history.push(`/view-file/${file.id}/${file.fileName}`);
                      }}
                    >
                      {getFileSource(file)}
                      <p className="text-center">{file.fileName}</p>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 100,
                      }}
                      className=" text-black text-decoration-none text-wrap cursor-pointer"
                      onClick={() => {
                        setPath([...path, file.fileName]);
                        setSupploerDocumentFolders(file.children);
                        setShowProjectDocuments(false);
                        setShowSupplierDocuments(true);
                      }}
                    >
                      <img
                        className="w-full object-scale-down block mx-auto"
                        src={FolderIcon}
                        alt=""
                      />
                      <p className="break-words text-sm text-center">
                        {file.fileName}
                      </p>
                    </div>
                  )
                )
              )
            ) : showSupplierDocuments ? (
              supploerDocumentFolders?.length === 0 ? (
                <NoFIleOrFolders />
              ) : (
                supploerDocumentFolders?.map(
                  (file) =>
                    file.type === "file" && (
                      <div
                        key={file.id}
                        className="flex flex-col items-center gap-2 cursor-pointer"
                        onClick={() =>
                          history.push(`/view-file/${file.id}/${file.fileName}`)
                        }
                      >
                        {getFileSource(file)}
                        <p className="text-center">{file.fileName}</p>
                      </div>
                    )
                )
              )
            ) : (
              <NoFIleOrFolders />
            )}
          </div>
        )}
      </div>
    </>
  );
};
