import { FileIcon } from "./icons/icons";
import "./file.scss";
import { useHistory } from "react-router-dom";

const File = ({ name, file }) => {
  const history = useHistory();

  return (
    <div
      className="file"
      onClick={() => history.push("/view-document/" + file?.id)}
    >
      <div className="file-icon">
        <FileIcon />
      </div>
      <p className="file-name">{name}</p>
    </div>
  );
};

export default File;
