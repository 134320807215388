import React from "react";
import "./chatBody.scss";
import ChatList from "../ChatList/ChatList";
import ChatContent from "../ChatContent/ChatContent";

const ChatBody = () => {
  return (
    <div className="main__chatbody">
      {/* <ChatList /> */}
      <ChatContent />
    </div>
  );
};

export default ChatBody;
