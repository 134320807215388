import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  businessRegistration: {}, 
  pending: false,
  error: false,
  errorMsg: "",
};

const businessRegistration = createSlice({
  name: "businessRegistration",
  initialState,
  reducers: {
    handleBusinessRegistrationRequest: (state) => {
      state.pending = true;
    },
   
    getBusinessRegistrationSuccess: (state, action) => {
      state.businessRegistration = action.payload; 
      state.pending = false;
      state.errorMsg = "";
      console.log(state)
    },
    
    handleBusinessRegistrationError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
    addBusinessMessageSuccess: (state, action) => {
        const newMessage = action.payload;
        state.businessRegistration.businessRegistrationMessages = [
            newMessage, ...state.businessRegistration.businessRegistrationMessages
        ];
        console.log("loging state:",state)
    },
  },
});

export const {
  handleBusinessRegistrationRequest,
  addBusinessMessageSuccess,
  handleBusinessRegistrationError,
  getBusinessRegistrationSuccess,
} = businessRegistration.actions;

export default businessRegistration.reducer;
