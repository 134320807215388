import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingComponent = () => {
  return (
    <div className="flex items-center justify-center h-48 w-full">
      <Spinner animation="grow" variant="success" />
    </div>
  );
};

export default LoadingComponent;
