import React from "react";
import { Row, Col, Card, Tab, Nav, ListGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import UpdateRecord from "./UpdateRecord";
import { useState } from "react";

export default function RecordDocumentData({
  data,
  doc,
  document,
  document_id,
  auth,
}) {
  const [click, setClick] = useState(false);
  const [toggleUpdate, setToggleUpdate] = useState(false);

  const handleUpdateToggle = () => {
    setToggleUpdate(!toggleUpdate);
  };
  // const handleClick = () => {
  //   setClick(!click);
  // };

  console.log("data");
  console.log(data);
  console.log(doc);
  return (
    <>
      {auth?.permissions.roleId === "2" ? (
        <>
          <UpdateRecord
            document_id={document_id}
            data={data}
            close={handleUpdateToggle}
            show={toggleUpdate}
            document={document}
          />
          <div className="basic-list-group" style={{ marginTop: "30px" }}>
            <h4
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Edit records details{" "}
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                  onClick={() => handleUpdateToggle()}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#4D06A5",
                    cursor: "pointer",
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                  />
                </svg>
              </span>
            </h4>
            <ListGroup as="ul">
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                  <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                    Project:{" "}
                  </h6>
                  <span className="text-dark" style={{ marginTop: "-8px" }}>
                    {data?.project}
                  </span>
                </div> */}
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                  Name of supplier:{" "}
                </h6>
                <span className="text-dark" style={{ marginTop: "-8px" }}>
                  {data?.nameOfSupplier}
                </span>
              </div> */}
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                  Item Procured:{" "}
                </h6>
                <span className="text-dark" style={{ marginTop: "-8px" }}>
                  {data?.itemProcured}
                </span>
              </div> */}
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                  Tr Number:{" "}
                </h6>
                <span className="text-dark" style={{ marginTop: "-8px" }}>
                  {data?.trNo}
                </span>
              </div> */}
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                  <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                    Pro Method:{" "}
                  </h6>
                  <span style={{ marginTop: "-8px" }} className="text-dark">
                    {data?.proMethod}
                  </span>
                </div>
                 <div style={{ display: "flex", alignItems: "center" }}>
                  <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                    Justification for method:{" "}
                  </h6>
                  <span style={{ marginTop: "-8px" }} className="text-dark">
                    {data?.justificationForMethod}
                  </span>
                </div> */}
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                  Supplier Gender:{" "}
                </h6>
                <span style={{ marginTop: "-8px" }} className="text-dark">
                  {data?.suppliersGender}
                </span>
              </div> */}
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                  <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                    Date of submission:{" "}
                  </h6>
                  <span style={{ marginTop: "-8px" }} className="text-dark">
                    {" "}
                    {moment(data?.dateOfSubmission).format("MM/DD/YYYY")}
                  </span>
                </div> */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                  Currency Type:{" "}
                </h6>
                <span style={{ marginTop: "-8px" }} className="text-dark">
                  {data?.currencyType}
                </span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                  Currency Amount:
                </h6>
                <span style={{ marginTop: "-8px" }} className="text-dark">
                  {data?.currencyAmount}
                </span>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                  Total in dalasis:{" "}
                </h6>
                <span style={{ marginTop: "-8px" }} className="text-dark">
                  {data?.totalInDalasis}
                </span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                  Initial Est:{" "}
                </h6>
                <span style={{ marginTop: "-8px" }} className="text-dark">
                  {data?.initialEst}
                </span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                  Approved Amount:{" "}
                </h6>
                <span style={{ marginTop: "-8px" }} className="text-dark">
                  {data?.approvedAmount}
                </span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                  Approved date:{" "}
                </h6>
                <span style={{ marginTop: "-8px" }} className="text-dark">
                  {moment(data?.approvedDate).format("MM/DD/YYYY")}
                </span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                  Remarks:{" "}
                </h6>
                <span style={{ marginTop: "-8px" }} className="text-dark">
                  {data?.remarks}
                </span>
              </div>
            </ListGroup>
          </div>
        </>
      ) : (
        <div className="basic-list-group" style={{ marginTop: "30px" }}>
          <h4
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            All records details{" "}
          </h4>
          <ListGroup as="ul">
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Institution:{" "}
              </h6>
              <span className="text-dark" style={{ marginTop: "-8px" }}>
                {data?.institution}
              </span>
            </div> */}
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Project:{" "}
              </h6>
              <span className="text-dark" style={{ marginTop: "-8px" }}>
                {data?.project || "N/A"}
              </span>
            </div> */}
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Name of supplier:{" "}
              </h6>
              <span className="text-dark" style={{ marginTop: "-8px" }}>
                {data?.nameOfSupplier || "N/A"}
              </span>
            </div> */}
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Item Procured:{" "}
              </h6>
              <span className="text-dark" style={{ marginTop: "-8px" }}>
                {data?.itemProcured || "N/A"}
              </span>
            </div> */}
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Tr Number:{" "}
              </h6>
              <span className="text-dark" style={{ marginTop: "-8px" }}>
                {doc?.referenceNumber}
              </span>
            </div> */}
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Pro Method:{" "}
              </h6>
              <span style={{ marginTop: "-8px" }} className="text-dark">
                {doc?.procurementMethod}
              </span>
            </div> */}
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Justification for method:{" "}
              </h6>
              <span style={{ marginTop: "-8px" }} className="text-dark">
                {doc?.justificationOfMethod}
              </span>
            </div> */}
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Supplier Gender:{" "}
              </h6>
              <span style={{ marginTop: "-8px" }} className="text-dark">
                {data?.suppliersGender || "N/A"}
              </span>
            </div> */}
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Date of submission:{" "}
              </h6>
              <span style={{ marginTop: "-8px" }} className="text-dark">
                {!data?.dateOfSubmissionmoment
                  ? "N/A"
                  : (data?.dateOfSubmission).format("MM/DD/YYYY")}
              </span>
            </div> */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Currency Type:{" "}
              </h6>
              <span style={{ marginTop: "-8px" }} className="text-dark">
                {data?.currencyType || "N/A"}
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Currency Amount
              </h6>
              <span style={{ marginTop: "-8px" }} className="text-dark">
                {data?.currencyAmount || "N/A"}
              </span>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Total in dalasis:{" "}
              </h6>
              <span style={{ marginTop: "-8px" }} className="text-dark">
                {data?.totalInDalasis || "N/A"}
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Initial Est:{" "}
              </h6>
              <span style={{ marginTop: "-8px" }} className="text-dark">
                {data?.initialEst || "N/A"}
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Approved Amount:{" "}
              </h6>
              <span style={{ marginTop: "-8px" }} className="text-dark">
                {data?.approvedAmount || "N/A"}
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Approved date:{" "}
              </h6>
              <span style={{ marginTop: "-8px" }} className="text-dark">
                {!data?.approvedDate
                  ? "N/A"
                  : moment(data?.approvedDate).format("MM/DD/YYYY")}
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Remarks:{" "}
              </h6>
              <span style={{ marginTop: "-8px" }} className="text-dark">
                {data?.remarks || "N/A"}
              </span>
            </div>
          </ListGroup>
        </div>
      )}
    </>
  );
}
