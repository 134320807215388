import http from "../http-common";
import {
  CancelPending,
  loginError,
  loginErrorVerify,
  loginRequest,
  loginSuccess,
} from "../redux/Slices/Auth";
import { toast } from "react-toastify";

export const nextView = async () => {
  try {
    return await http.get("/users/check_user_table");
  } catch (error) {
    toast.error(error.response.data.message);
    return error.response;
  }
};

export const loginUser = (user, dispatch, history, setLoading) => {
  let verify = 0;
  dispatch(loginRequest());
  http
    .post("/auth/login", user)
    .then(async (response) => {
      console.log("response");
      console.log(response);
      if (response.data.mfa) {
        history.push("/check-authorization");
        localStorage.setItem("token", response.data.tokenSecret);
        setLoading(false);
      } else {
        localStorage.setItem("token", response.data.token);
        verifyUser(dispatch, history, verify);
      }

      //history.push("/check-authorization");
      // history.push("/");
    })
    .catch((error) => {
      setLoading(false);
      toast.error(error.response?.data.message);
      dispatch(loginError(error));
    });
};

//From token || localStorage, verify user session.
export const middleware = async (dispatch) => {
  dispatch(CancelPending());
  try {
    const response = await http.get("/users/user_2fa_promt");
    return response;
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch(loginErrorVerify(error));
  }
};

//Session
export const verifySession = async (session) => {
  try {
    return await http.post("/users/session", { session });
  } catch (error) {
    return error.response.data;
  }
};

//From token || localStorage, verify user session.
export const verifyUser = (dispatch, history, verify) => {
  if (!localStorage.getItem("token")) {
    history.push("/login");
  }
  http
    .get("/auth/verify")
    .then((response) => {
      if (response.data.success) {
        dispatch(loginSuccess(response.data.userData));
        if (!verify) {
          history.push("/");
        }
      }
    })
    .catch((error) => {
      toast.error(error.response);
      dispatch(loginErrorVerify({ error, history }));
    });
};

export const registerUser = async (user) => {
  const data = await http
    .post(`auth/register`, user)
    .then((response) => {
      toast.success(response.data.message);
      window.location.reload();
      return response.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err.response.data.message;
    });

  return data;
};

export const verifyUserTwoFA = async (code, dispatch, history) => {
  let verify = 0;

  const data = await http
    .post(`user/user2fa/verify`, code)
    .then((response) => {
      toast.success(response.data.message);
      // console.log(response.data);
      // window.location.reload();
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        // history.push("/");
        verifyUser(dispatch, history, verify);
      }
      return response.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err.response.data.message;
    });

  return data;
};

export const enableTwoFA = async (history) => {
  const data = await http
    .post(`user/user2FaEnable`)
    .then((response) => {
      toast.success(response.data.message);
      // console.log(response.data);
      history.push("/validate-2fa");
      return response.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err.response.data.message;
    });

  return data;
};

export const getUser2fa = async (history) => {
  const data = await http
    .post(`user/user2fa/getUser2fa`)
    .then((response) => {
      toast.success(response.data.message);
      // console.log(response.data);
      return response.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err.response.data.message;
    });

  return data;
};

export const verifyUserTwoFASetup = async (code, history) => {
  const data = await http
    .post(`user/user2fa/verfiySetup`, code)
    .then((response) => {
      toast.success(response.data.message);
      // console.log(response.data);
      history.push("/verification-success");
      return response.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err.response.data.message;
    });

  return data;
};

// export const logout = () => {};
