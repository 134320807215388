import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { verifyUserTwoFA } from "../../../services/AuthService";

const CheckAuthorization = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [code, setCode] = useState({
    code: "",
  });
  const submit = async (e) => {
    e.preventDefault();
    try {
      console.log("codeee");
      console.log(code);
      const verifyResponse = await verifyUserTwoFA(code, dispatch, history);
      console.log(verifyResponse);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <div
            className="row"
            style={{ justifyContent: "center", textAlign: "center" }}
          >
            <div className="col-xl-6 col-lg-6">
              <div className="card" style={{ background: "#F7F7F7" }}>
                <div className="card-body">
                  <div className="basic-form">
                    <form>
                      <div className="row" style={{ justifyContent: "center" }}>
                        <div className="form-group col-md-9 mb-3">
                          <label
                            className="form-control-label text-dark mb-3"
                            style={{ fontSize: "18px", color: "#404340" }}
                          >
                            Enable the 6-digit 2FA code
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            onChange={(e) =>
                              setCode({ ...code, code: e.target.value })
                            }
                          />
                        </div>

                        <div className="col-md-12">
                          <button
                            type="submit"
                            className="btn btn-sm text-white"
                            style={{ background: "#4D06A5" }}
                            onClick={(e) => submit(e)}
                          >
                            Verify
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <button
                className="mb-4"
                style={{ background: "transparent", border: "0" }}
              >
                Logout / Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckAuthorization;
