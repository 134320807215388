import { createSlice } from "@reduxjs/toolkit";

export const userManagementSlice = createSlice({
  name: "User Management",
  initialState: {
    users: [],
    userRoles: [],
    roles: [],
    permissions: [],
    businessClient: [],
    businessClientID: null,
    searchBusiness: [],
    searchBusinessClient: [],
    allBusiness: [],
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleUserManagementRequest: (state) => {
      state.pending = true;
    },
    createUserManagementSuccess: (state) => {
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },
    getUserManagementSuccess: (state, action) => {
      state.pending = false;
      state.users = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getUserManagementRoleSuccess: (state, action) => {
      state.pending = false;
      state.userRoles = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getAllRolesSuccess: (state, action) => {
      state.pending = false;
      state.roles = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getBusinessClientSucess: (state, action) => {
      state.pending = false;
      state.businessClient = action.payload;
      state.error = false;
      state.errorMsg = "";
    },

    handleReset: (state) => {
      state.searchBusinessClient = [];
    },

    getBusinessClientIDSuccess: (state, action) => {
      state.pending = false;
      state.businessClientID = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getAllPermissionsSuccess: (state, action) => {
      state.pending = false;
      state.permissions = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getBusinessSearchSuccess: (state, action) => {
      state.pending = false;
      state.searchBusiness = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    handleSearchReset: (state) => {
      state.searchBusiness = [];
    },
    getBusinessSearchError: (state, action) => {
      state.pending = false;
      state.searchBusiness = [];
      state.error = true;
      state.errorMsg = action.payload;
    },
    getSearchBusinessClientSuccess: (state, action) => {
      state.pending = false;
      state.searchBusinessClient = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getAllBusinessSuccess: (state, action) => {
      state.pending = false;
      state.allBusiness = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    handleUserManagementError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
  },
});

export const {
  handleUserManagementRequest,
  createUserManagementSuccess,
  getUserManagementSuccess,
  getUserManagementRoleSuccess,
  getAllRolesSuccess,
  getAllPermissionsSuccess,
  getBusinessClientSucess,
  getBusinessClientIDSuccess,
  getBusinessSearchSuccess,
  getSearchBusinessClientSuccess,
  getAllBusinessSuccess,
  handleUserManagementError,
  getBusinessSearchError,
  handleSearchReset,
  handleReset,
} = userManagementSlice.actions;
export default userManagementSlice.reducer;
