import { createSlice } from "@reduxjs/toolkit";

export const commentSlice = createSlice({
  name: "comments",
  initialState: {
    comments: [],
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleCommentRequest: (state) => {
      state.pending = true;
    },
    createCommentSuccess: (state) => {
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },
    getCommentSuccess: (state, action) => {
      state.pending = false;
      state.comments = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    handleCommentError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
    addLetterSuccess: (state, action) => {
      const newLetter = action.payload; 
      state.document.documentLetter = [newLetter, ...state.document.documentLetter]; 
    },
    updateLetterSuccess: (state, action) => {
      const updatedLetter = action.payload;
      const index = state.document?.documentLetter?.findIndex(
        (letter) => letter.id === updatedLetter.id
    );
  }
  },
});

export const {
  handleCommentRequest,
  createCommentSuccess,
  getCommentSuccess,
  handleCommentError,
} = commentSlice.actions;
export default commentSlice.reducer;
