import React, { Fragment, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Modal, Button, Card } from "react-bootstrap";
import {
  AddLetterSchema,
} from "../../../Validations/AddLetterValidations";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "../Backdrop/LoadingSpinner";
import { addLetterToDocument } from "../../../services/documentsService";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import draftToHtml from 'draftjs-to-html';

const AddLetterModal = ({ document_id, show, close, businessData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [letterBody, setLetterBody] = useState()
 
  const { pending } = useSelector((state) => state.letters);
  const [letters, setLetters] = useState({
    documentId: document_id,
    title: "",
    content: "",
  });

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const onEditorStateChange = (newState) => {
    setEditorState(newState);
    setLetterBody(draftToHtml(convertToRaw(newState.getCurrentContent())));
  };

  

  return (
    <Modal className="fade " size="xl" show={show} onHide={() => close()}>
      <Modal.Header>
        <Modal.Title>Add Letter</Modal.Title>
        <Button
          variant=""
          className="btn-close"
          onClick={() => close()}
        ></Button>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Card.Body>
           
              <Formik
                initialValues={letters}
                validationSchema={AddLetterSchema}
                onSubmit={async (values) => {
                  try {
                    setLoading(true);
                    const formData = {
                      ...values,
                      documentId: letters.documentId,
                      content: letterBody
                    };
                    console.log(formData)
                    await addLetterToDocument(formData, dispatch, history);
                    setLetterBody("")
                    setLoading(false);
                    close();
                  } catch (error) {
                    console.error(error);
                    setLoading(false);
                    close();
                  }
                }}
              >
                {(formik) => {
                  const {
                    errors,
                    touched,
                    isValid,
                    dirty,
                    handleChange,
                    handleBlur,
                    values,
                  } = formik;

                  return (
                    <Form className="basic-form" >
                      <div className="">
                        <div className="col-xl-12 col-lg-12">
                          <div className="basic-form">
                            <div className="" >
                              <div className="form-group mb-3 col-md-12" 
                                 >
                                <label htmlFor="" className="text-dark">
                                  Title
                                </label>
                                <Field
                                  type="text"
                                  name="title"
                                  id="title"
                                  className={
                                    errors.title && touched.title
                                      ? "input-error form-control form-control-sm"
                                      : "form-control "
                                  }
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="title"
                                  component="span"
                                  className="text-danger"
                                  style={{ color: "#4D06A5" }}
                                />
                              </div>

                              <div className="form-group mb-3 col-md-12" style={{border: "1px solid #ccc", borderRadius: "20px", padding: "15px"}}>
                                  <Editor
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    editorStyle={{ 
                                      minHeight: '260px', 
                                      maxHeight: '260px', 
                                      backgroundColor: "transparent", 
                                      padding: "10px", 
                                      color: "#000",
                                      overflowY: "scroll"
                                    }} 
                                    onEditorStateChange={onEditorStateChange}
                                  />
                                </div>

                              <div className="form-group" style={{marginTop: "20px"}}>
                                <button
                                  type="submit"
                                  
                                  className={
                                    !(dirty && isValid)
                                      ? " btn btn-sm mt-10  disabled-btn text-white"
                                      : "btn btn-sm mt-3 text-white "
                                  }
                                  style={{ background: "#4D06A5", marginRight: "auto", }}
                                  disabled={!(dirty && isValid)}
                                >
                                  Add letter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Card.Body>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddLetterModal;
