import axios from "axios";
import { toast } from "react-toastify";
import { store } from "./redux/store";
import { logout } from "./redux/Slices/Auth";
import {
  resetIsServerError,
  setIsServerError,
} from "./redux/Slices/SystemSlice";

const axiosConfig = axios.create({
  // baseURL: "http://localhost:9000/api/v1/",
  // baseURL: "http://gppa-con.zigtech.net/api/v1",
  baseURL: "https://api.gppa.gm/api/v1",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    credentials: "include",
  },
});

axiosConfig.interceptors.request.use(function (config) {
  let token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

const fireLogout = () => {
  if (store.getState().auth.logged) store.dispatch(logout());
};

axiosConfig.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      toast.error(
        error.response?.data?.message || "Authorization error: login again"
      );
      fireLogout();
    } else if (error.response && error.response.status === 500) {
      if (
        error.response.data?.message ===
        "Something went wrong, try again later! jwt malformed"
      ) {
        fireLogout();
      } else store.dispatch(setIsServerError());
    } else {
      store.dispatch(resetIsServerError());
    }
    return Promise.reject(error);
  }
);

export default axiosConfig;
