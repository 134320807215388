import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearItems, removeItem } from '../../../../redux/Slices/BreadCrumbSlice';
import { useHistory } from 'react-router-dom'; 
import "./breadcrumb.scss";

const Breadcrumb = () => {
  const items = useSelector((state) => state.breadcrumb.items);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleItemClick = (index) => {
    const itemsToRemove = items?.length - index - 1;
  
    if (index === items?.length - 1) {
      history.goBack();
      dispatch(removeItem());
    } else if (index === 0) {
      history.push('/all-documents-copy');
      dispatch(clearItems());
    } else {
      dispatch(clearItems(itemsToRemove));
    }
  };

  useEffect(() => {
    const handleBrowserBackButton = () => {
      dispatch(clearItems());
    };

    window.addEventListener('popstate', handleBrowserBackButton);

    return () => {
      window.removeEventListener('popstate', handleBrowserBackButton);
    };
  }, [dispatch]);

  return (
    <div className="breadcrumb-list">
      <ul className="breadcrumb-list__item">
        {items.map((item, index) => (
          <li key={index} className="breadcrumb-list__item--text">
            <div onClick={() => handleItemClick(index)}>{item}</div>
            {index !== items?.length - 1 && <span className="divider"> / </span>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Breadcrumb;
