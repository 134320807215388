import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { addRecordDocument } from "../../../services/documentsService";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "../Backdrop/LoadingSpinner";
import { AddRecordSchema } from "../../../Validations/AddRecordValidations";
import { getProcurementMethod } from "../../../services/procurementMethodService";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import LoadingSpinner from "../Backdrop/LoadingSpinner";
import Select from "react-select";

export default function AddRecordToReadyForDispat({
  document_id,
  document,
  close,
  show,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    getProcurementMethod(dispatch);
  }, []);

  const { pending, procurementMethods } = useSelector(
    (state) => state.procurementMethods
  );

  const [records, setRecords] = useState({
    documentId: document_id,
    institution: document?.institution,
    project: document?.documentTitle,
    // nameOfSupplier: "",
    // itemProcured: "",
    suppliersGender: "",
    currencyType: "",
    currencyAmount: 0,
    totalInDalasis: 0,
    initialEst: 0,
    approvedAmount: 0,
    approvedDate: "12/10/2023",
    remarks: "",
    supplier: [{ supplier: "", name: "", gender: "", awards: "" }],
  });

  if (loading) {
    return <Loading />;
  }

  if (pending) {
    return <Loading />;
  }

  const awardedLotsOptions = [
    { label: "Lot 1", value: "1" },
    { label: "Lot 2", value: "2" },
    { label: "Lot 3", value: "3" },
    { label: "Lot 4", value: "4" },
    { label: "Lot 5", value: "5" },
  ];

  const dummyOptions = [
    { value: "supplier1", label: "Supplier 1", id: "1", gender: "Male" },
    { value: "supplier2", label: "Supplier 2", id: "2", gender: "Female" },
    { value: "supplier3", label: "Supplier 3", id: "3", gender: "Male" },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "15px",
      padding: "3px",
    }),
  };

  return (
    <Modal className="fade" size="xl" show={show} onHide={() => close()}>
      <Modal.Header>
        <Modal.Title>
          <h3>Add Record</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Formik
            initialValues={records}
            validationSchema={AddRecordSchema}
            onSubmit={async (values) => {
              try {
                setLoading(true);
                const formData = {
                  ...values,
                  institution: document?.institution,
                };
                addRecordDocument(formData, dispatch, history);
                setLoading(false);
                close();
              } catch (error) {
                console.error(error);
                toast.error("error adding record");
                setLoading(false);
              }
            }}
          >
            {(formik) => {
              const {
                errors,
                touched,
                isValid,
                dirty,
                handleChange,
                handleBlur,
                values,
              } = formik;

              return (
                <Form className="basic-form" style={{ marginTop: "10px" }}>
                  <div className="row">
                    <div className="col-xl-12 col-lg-12">
                      <div className="basic-form">
                        <div className="row">
                          <div
                            style={{ display: "flex", justifyContent: "space" }}
                          >
                            <div className="form-group mb-3 col-md-6">
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Project
                                </label>
                                <Field
                                  type="text"
                                  name="project"
                                  id="project"
                                  className={
                                    errors.project && touched.project
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="project"
                                  component="span"
                                  className="text-danger"
                                  style={{ color: "#4D06A5" }}
                                />
                              </div>
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Supplier's gender
                                </label>
                                <Field
                                  as="select"
                                  type="text"
                                  name="suppliersGender"
                                  id="suppliersGender"
                                  className={
                                    errors.suppliersGender &&
                                    touched.suppliersGender
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                >
                                  <option value="">Select gender</option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                </Field>
                                <ErrorMessage
                                  name="suppliersGender"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Currency Type
                                </label>
                                <Field
                                  type="text"
                                  as="select"
                                  name="currencyType"
                                  id="currencyType"
                                  className={
                                    errors.currencyAmount &&
                                    touched.currencyAmount
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                >
                                  <option value="">Select current type</option>
                                  <option value="dalasi">Dalasis</option>
                                  <option value="dollar">Dollar</option>
                                  <option value="euro">Euro</option>
                                  <option value="pound">Pound</option>
                                  <option value="cfa">CFA</option>
                                </Field>
                                <ErrorMessage
                                  name="currencyType"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Currency Amount
                                </label>
                                <Field
                                  type="text"
                                  name="currencyAmount"
                                  id="currencyAmount"
                                  className={
                                    errors.currencyAmount &&
                                    touched.currencyAmount
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="currencyAmount"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Total in Dalasis
                                </label>
                                <Field
                                  type="text"
                                  name="totalInDalasis"
                                  id="totalInDalasis"
                                  className={
                                    errors.totalInDalasis &&
                                    touched.totalInDalasis
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="totalInDalasis"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Initial Est
                                </label>
                                <Field
                                  type="text"
                                  name="initialEst"
                                  id="initialEst"
                                  className={
                                    errors.initialEst && touched.initialEst
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="initialEst"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Approved Amount
                                </label>
                                <Field
                                  type="text"
                                  name="approvedAmount"
                                  id="approvedAmount"
                                  className={
                                    errors.approvedAmount &&
                                    touched.approvedAmount
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="approvedAmount"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Approved Date
                                </label>
                                <Field
                                  type="date"
                                  name="approvedDate"
                                  id="approvedDate"
                                  className={
                                    errors.approvedDate && touched.approvedDate
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="approvedDate"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group mb-3 col-lg-12">
                            {/* ////////////////////////////////////////////////////////////// */}

                            <FieldArray name="supplier">
                              {({ push, remove }) => (
                                <div className="col-xl-12 col-lg-12">
                                  <div className="">
                                    <div className="">
                                      <div className="features">
                                        <div className="lots">
                                          <h5>Supplier Details</h5>
                                          <div
                                            className="btn btn-sm mt-3 text-white "
                                            style={{ background: "#4D06A5" }}
                                            onClick={() =>
                                              push({
                                                supplier: "",
                                                name: "",
                                                gender: "",
                                                awards: { name: "" },
                                              })
                                            }
                                          >
                                            Add Supplier
                                          </div>
                                        </div>
                                        {values?.supplier?.map(
                                          (value, index) => {
                                            return (
                                              <div
                                                key={index}
                                                className="lots-container"
                                              >
                                                <div className="form-group mb-8">
                                                  <div
                                                    className="form-container"
                                                    style={{
                                                      marginTop: "20px",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    <div className="form-group col-md-3">
                                                      <label className="form-control-label">
                                                        Supplier Name
                                                      </label>

                                                      <Select
                                                        options={dummyOptions}
                                                        styles={customStyles}
                                                      />
                                                      <ErrorMessage
                                                        name={`supplier[${index}].name`}
                                                        component="span"
                                                        className="text-danger"
                                                      />
                                                    </div>

                                                    <div className="form-group col-md-3 ">
                                                      <label
                                                        htmlFor="features"
                                                        className="form-control-label"
                                                      >
                                                        Gender
                                                      </label>
                                                      <Field
                                                        as="select"
                                                        type="number"
                                                        min="0"
                                                        placeholder="0"
                                                        name={`supplier[${index}].gender`}
                                                        className="form-control form-select form-control-sm"
                                                      >
                                                        <option value="male">
                                                          Male
                                                        </option>
                                                        <option value="male">
                                                          Female
                                                        </option>
                                                      </Field>
                                                      <ErrorMessage
                                                        name={`supplier[${index}].gender`}
                                                        component="span"
                                                        className="text-danger"
                                                      />
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                      <label
                                                        htmlFor="features"
                                                        className="form-control-label"
                                                      >
                                                        Awarded Lots
                                                      </label>
                                                      <Select
                                                        isMulti
                                                        name="colors"
                                                        options={
                                                          awardedLotsOptions
                                                        }
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        styles={customStyles}
                                                      />
                                                      <ErrorMessage
                                                        name={`supplier[${index}].award`}
                                                        component="span"
                                                        className="text-danger"
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "flex-end",
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {index > 0 && (
                                                        <span
                                                          onClick={() =>
                                                            remove(index)
                                                          }
                                                          style={{
                                                            marginLeft: "auto",
                                                            color: "red",
                                                            marginTop: "20px",
                                                          }}
                                                        >
                                                          <i className="fas fa-trash"></i>
                                                        </span>
                                                      )}
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </FieldArray>

                            {/* //////////////////////////////////////////////////////// */}
                          </div>
                          <div className="form-control-sm">
                            <label htmlFor="" className="text-dark">
                              Remarks
                            </label>
                            <textarea
                              name="remarks"
                              id="remarks"
                              className="form-control"
                              onChange={handleChange}
                              cols="30"
                              rows="8"
                            ></textarea>
                          </div>

                          <div className="form-group">
                            <button
                              type="submit"
                              className={
                                !(dirty && isValid)
                                  ? " btn btn-sm mt-3  disabled-btn text-white"
                                  : "btn btn-sm mt-3 text-white "
                              }
                              style={{ background: "#4D06A5" }}
                              disabled={!(dirty && isValid)}
                            >
                              Add Record
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
}
