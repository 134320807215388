import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { toast } from "react-toastify";
import http from "../../../http-common";
import Swl from "sweetalert2";
import LoadingComponent from "../generic/LoadingComponent";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import BackBtn from "../generic/BackBtn";
const ViewFiles = () => {
  const [docs, setDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [blobData, setBlobData] = useState();
  const [isNotFound, setIsNotFound] = useState(false);

  const { id, fileName } = useParams();
  const getFileApplicationType = () => {
    if (fileName?.includes(".doc"))
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    else if (fileName?.includes(".xls"))
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    else if (fileName?.includes(".csv")) return "text/csv";
    else return "application/pdf";
  };

  const fetchBlob = async () => {
    const { data } = await http.get(`/files/readFile/${id}`, {
      responseType: "blob",
    });

    const blob = new Blob([data], { type: getFileApplicationType() });
    setBlobData(blob);
    return blob;
  };

  const readFileData = async () => {
    setIsLoading(true);
    try {
      const blob = await fetchBlob();
      const blobUrl = URL.createObjectURL(blob);

      setDocs([{ uri: blobUrl, fileName: fileName }]);
    } catch (err) {
      console.log(err);
      if (err?.response?.status === 404) {
        Swl.fire({
          icon: "error",
          text: "We cannot find the file you requested in our system. Please contact system admin incase our sytem is mistaking.",
        });
        setIsNotFound(true);
      } else {
        Swl.fire({
          icon: "error",
          text: err.response?.data?.message || "Cannot view this file",
        });
      }
    }

    setIsLoading(false);
  };

  const prepareDownloadBlob = async () => {
    setIsLoading(true);
    try {
      await fetchBlob();
    } catch (err) {
      console.log(err);
      toast.error(err.response?.data?.message || "Cannot view this file");
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!fileName?.includes(".pdf")) {
      prepareDownloadBlob();
    } else {
      readFileData();
    }
  }, []);

  const downloadFile = () => {
    if (blobData) {
      const blobUrl = URL.createObjectURL(blobData);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    }
  };
  return (
    <div>
      <div className="mb-3">
        <BackBtn />
      </div>
      <div
        style={{
          height: 700,
          width: "100%",
          overflow: "hidden",
        }}
      >
        {isLoading ? (
          <LoadingComponent />
        ) : !fileName?.includes(".pdf") ? (
          <div className="py-20 flex items-center justify-center flex-col gap-3">
            <h6 className="h6">
              Cannot read this file please download it and view it in your
              computer instead
            </h6>
            <button
              onClick={downloadFile}
              className="btn btn-sm btn-success flex items-center text-nowrap"
            >
              Download
            </button>
          </div>
        ) : isNotFound ? (
          <div className="py-20 flex items-center justify-center flex-col gap-3">
            <h6 className="h6">
              We cannot find the file you requested in our system. Please
              contact system admin incase our sytem is mistaking.
            </h6>
          </div>
        ) : (
          <DocViewer
            style={{ width: "100%" }}
            documents={docs}
            pluginRenderers={DocViewerRenderers}
          />
        )}
      </div>
    </div>
  );
};

export default ViewFiles;
