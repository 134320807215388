export const ExcelIcon = () => {
  return(
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
   
    viewBox="0 0 190 166"
  >
    <defs>
      <rect id="a" width="142" height="166" rx="8"></rect>
      <path id="d" d="M0 41H71V83H0z"></path>
      <path id="h" d="M71 41H142V83H71z"></path>
      <path id="l" d="M0 -1H71V41H0z"></path>
      <path id="p" d="M71 -1H142V41H71z"></path>
      <path id="t" d="M0 83H71V125H0z"></path>
      <path id="x" d="M71 83H142V125H71z"></path>
      <path id="B" d="M0 125H71V167H0z"></path>
      <path id="F" d="M71 125H142V167H71z"></path>
      <rect id="I" width="142" height="166" rx="8"></rect>
      <rect id="L" width="96" height="96" rx="8"></rect>
      <rect id="R" width="96" height="98" y="-1" rx="8"></rect>
      <filter
        id="f"
        width="102.8%"
        height="104.8%"
        x="-1.4%"
        y="-2.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dx="-1"
          in="SourceAlpha"
          result="shadowOffsetInner1"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner1"
          result="shadowMatrixInner1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="SourceAlpha"
          result="shadowBlurInner2"
          stdDeviation="0.5"
        ></feGaussianBlur>
        <feOffset
          dy="1"
          in="shadowBlurInner2"
          result="shadowOffsetInner2"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner2"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner2"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner2"
          result="shadowMatrixInner2"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixInner1"></feMergeNode>
          <feMergeNode in="shadowMatrixInner2"></feMergeNode>
        </feMerge>
      </filter>
      <filter
        id="j"
        width="143.7%"
        height="173.8%"
        x="-21.8%"
        y="-36.9%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="-1"
          in="SourceAlpha"
          result="shadowOffsetInner1"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner1"
          result="shadowMatrixInner1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="SourceAlpha"
          result="shadowBlurInner2"
          stdDeviation="10"
        ></feGaussianBlur>
        <feOffset
          dy="11"
          in="shadowBlurInner2"
          result="shadowOffsetInner2"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner2"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner2"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner2"
          result="shadowMatrixInner2"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
        ></feColorMatrix>
        <feOffset
          dx="1"
          dy="1"
          in="SourceAlpha"
          result="shadowOffsetInner3"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner3"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner3"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner3"
          result="shadowMatrixInner3"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixInner1"></feMergeNode>
          <feMergeNode in="shadowMatrixInner2"></feMergeNode>
          <feMergeNode in="shadowMatrixInner3"></feMergeNode>
        </feMerge>
      </filter>
      <filter
        id="n"
        width="101.4%"
        height="102.4%"
        x="-.7%"
        y="-1.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="-1"
          in="SourceAlpha"
          result="shadowOffsetInner1"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
        ></feColorMatrix>
      </filter>
      <filter
        id="r"
        width="102.8%"
        height="104.8%"
        x="-1.4%"
        y="-2.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="-1"
          in="SourceAlpha"
          result="shadowOffsetInner1"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner1"
          result="shadowMatrixInner1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
        ></feColorMatrix>
        <feOffset
          dx="2"
          in="SourceAlpha"
          result="shadowOffsetInner2"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner2"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner2"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner2"
          result="shadowMatrixInner2"
          values="0 0 0 0 0 0 0 0 0 0.709803922 0 0 0 0 0.37254902 0 0 0 0.63 0"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixInner1"></feMergeNode>
          <feMergeNode in="shadowMatrixInner2"></feMergeNode>
        </feMerge>
      </filter>
      <filter
        id="v"
        width="102.8%"
        height="104.8%"
        x="-1.4%"
        y="-2.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dx="-1"
          in="SourceAlpha"
          result="shadowOffsetInner1"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner1"
          result="shadowMatrixInner1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.7 0"
        ></feColorMatrix>
        <feOffset
          dx="2"
          dy="1"
          in="SourceAlpha"
          result="shadowOffsetInner2"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner2"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner2"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner2"
          result="shadowMatrixInner2"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixInner1"></feMergeNode>
          <feMergeNode in="shadowMatrixInner2"></feMergeNode>
        </feMerge>
      </filter>
      <filter
        id="z"
        width="101.4%"
        height="102.4%"
        x="-.7%"
        y="-1.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="-1"
          in="SourceAlpha"
          result="shadowOffsetInner1"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner1"
          result="shadowMatrixInner1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        ></feColorMatrix>
        <feOffset
          dx="1"
          dy="1"
          in="SourceAlpha"
          result="shadowOffsetInner2"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner2"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner2"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner2"
          result="shadowMatrixInner2"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixInner1"></feMergeNode>
          <feMergeNode in="shadowMatrixInner2"></feMergeNode>
        </feMerge>
      </filter>
      <filter
        id="D"
        width="102.8%"
        height="104.8%"
        x="-1.4%"
        y="-2.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dx="-1"
          in="SourceAlpha"
          result="shadowOffsetInner1"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner1"
          result="shadowMatrixInner1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
        ></feColorMatrix>
        <feOffset
          dx="1"
          in="SourceAlpha"
          result="shadowOffsetInner2"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner2"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner2"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner2"
          result="shadowMatrixInner2"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
        ></feColorMatrix>
        <feOffset
          dx="2"
          dy="1"
          in="SourceAlpha"
          result="shadowOffsetInner3"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner3"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner3"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner3"
          result="shadowMatrixInner3"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixInner1"></feMergeNode>
          <feMergeNode in="shadowMatrixInner2"></feMergeNode>
          <feMergeNode in="shadowMatrixInner3"></feMergeNode>
        </feMerge>
      </filter>
      <filter
        id="H"
        width="102.8%"
        height="104.8%"
        x="-1.4%"
        y="-2.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dx="-1"
          in="SourceAlpha"
          result="shadowOffsetInner1"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner1"
          result="shadowMatrixInner1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0"
        ></feColorMatrix>
        <feOffset
          dx="2"
          dy="1"
          in="SourceAlpha"
          result="shadowOffsetInner2"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner2"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner2"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner2"
          result="shadowMatrixInner2"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixInner1"></feMergeNode>
          <feMergeNode in="shadowMatrixInner2"></feMergeNode>
        </feMerge>
      </filter>
      <filter
        id="J"
        width="117%"
        height="117%"
        x="-8.5%"
        y="-8.5%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="3"></feGaussianBlur>
      </filter>
      <filter
        id="Q"
        width="103.1%"
        height="103.1%"
        x="-1.6%"
        y="-1.5%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur
          in="SourceAlpha"
          result="shadowBlurInner1"
          stdDeviation="0.25"
        ></feGaussianBlur>
        <feOffset
          dx="-1"
          in="shadowBlurInner1"
          result="shadowOffsetInner1"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner1"
          result="shadowMatrixInner1"
          values="0 0 0 0 0.0199267733 0 0 0 0 0.65973108 0 0 0 0 0.312408742 0 0 0 1 0"
        ></feColorMatrix>
        <feGaussianBlur
          in="SourceAlpha"
          result="shadowBlurInner2"
          stdDeviation="0.5"
        ></feGaussianBlur>
        <feOffset
          dx="2"
          in="shadowBlurInner2"
          result="shadowOffsetInner2"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner2"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner2"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner2"
          result="shadowMatrixInner2"
          values="0 0 0 0 0 0 0 0 0 0.466666667 0 0 0 0 0.250980392 0 0 0 1 0"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixInner1"></feMergeNode>
          <feMergeNode in="shadowMatrixInner2"></feMergeNode>
        </feMerge>
      </filter>
      <filter
        id="S"
        width="141.7%"
        height="137.9%"
        x="-20.8%"
        y="-17%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="1"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3"
        ></feGaussianBlur>
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        ></feColorMatrix>
        <feOffset
          dy="1"
          in="SourceAlpha"
          result="shadowOffsetOuter2"
        ></feOffset>
        <feGaussianBlur
          in="shadowOffsetOuter2"
          result="shadowBlurOuter2"
          stdDeviation="0.5"
        ></feGaussianBlur>
        <feColorMatrix
          in="shadowBlurOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
          <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
        </feMerge>
      </filter>
      <linearGradient id="c" x1="6.294%" x2="103.402%" y1="0%" y2="103.5%">
        <stop offset="0%" stopColor="#007438"></stop>
        <stop offset="97.34%" stopColor="#008B44"></stop>
      </linearGradient>
      <linearGradient id="g" x1="107.466%" x2="6.294%" y1="107.831%" y2="0%">
        <stop offset="0%" stopColor="#00D576"></stop>
        <stop offset="97.026%" stopColor="#00A054"></stop>
      </linearGradient>
      <linearGradient id="k" x1="6.294%" x2="107.466%" y1="0%" y2="107.831%">
        <stop offset="0%" stopColor="#007E42"></stop>
        <stop offset="95.983%" stopColor="#009A50"></stop>
      </linearGradient>
      <linearGradient id="o" x1="6.294%" x2="107.466%" y1="0%" y2="107.831%">
        <stop offset="0%" stopColor="#00AD61"></stop>
        <stop offset="100%" stopColor="#00E18C"></stop>
      </linearGradient>
      <linearGradient
        id="s"
        x1="107.466%"
        x2="9.303%"
        y1="107.831%"
        y2="3.207%"
      >
        <stop offset="0%" stopColor="#003D20"></stop>
        <stop offset="100%" stopColor="#004F2A"></stop>
      </linearGradient>
      <linearGradient
        id="w"
        x1="107.466%"
        x2="9.303%"
        y1="107.831%"
        y2="3.207%"
      >
        <stop offset="0%" stopColor="#00C165"></stop>
        <stop offset="100%" stopColor="#008D46"></stop>
      </linearGradient>
      <linearGradient
        id="A"
        x1="107.466%"
        x2="9.303%"
        y1="107.831%"
        y2="3.207%"
      >
        <stop offset="0%" stopColor="#00522E"></stop>
        <stop offset="100%" stopColor="#003B20"></stop>
      </linearGradient>
      <linearGradient
        id="E"
        x1="107.466%"
        x2="9.303%"
        y1="107.831%"
        y2="3.207%"
      >
        <stop offset="0%" stopColor="#006D3D"></stop>
        <stop offset="100%" stopColor="#004C29"></stop>
      </linearGradient>
      <linearGradient
        id="M"
        x1="113.177%"
        x2="2.151%"
        y1="104.673%"
        y2="9.713%"
      >
        <stop offset="0%" stopColor="#008034"></stop>
        <stop offset="100%" stopColor="#004F21"></stop>
      </linearGradient>
      <linearGradient id="U" x1="29.468%" x2="97.963%" y1="50%" y2="50%">
        <stop offset="0%" stopColor="#F0F0F0"></stop>
        <stop offset="100%" stopColor="#FFF"></stop>
      </linearGradient>
      <pattern
        id="e"
        width="512"
        height="512"
        x="-512"
        y="-471"
        patternUnits="userSpaceOnUse"
      >
        <use xlinkHref="#a"></use>
      </pattern>
      <pattern
        id="i"
        width="512"
        height="512"
        x="-441"
        y="-471"
        patternUnits="userSpaceOnUse"
      >
        <use xlinkHref="#b"></use>
      </pattern>
      <pattern
        id="m"
        width="512"
        height="512"
        x="-512"
        y="-513"
        patternUnits="userSpaceOnUse"
      >
        <use xlinkHref="#c"></use>
      </pattern>
      <pattern
        id="q"
        width="512"
        height="512"
        x="-441"
        y="-513"
        patternUnits="userSpaceOnUse"
      >
        <use xlinkHref="#d"></use>
      </pattern>
      <pattern
        id="u"
        width="512"
        height="512"
        x="-512"
        y="-429"
        patternUnits="userSpaceOnUse"
      >
        <use xlinkHref="#e"></use>
      </pattern>
      <pattern
        id="y"
        width="512"
        height="512"
        x="-441"
        y="-429"
        patternUnits="userSpaceOnUse"
      >
        <use xlinkHref="#f"></use>
      </pattern>
      <pattern
        id="C"
        width="512"
        height="512"
        x="-512"
        y="-387"
        patternUnits="userSpaceOnUse"
      >
        <use xlinkHref="#g"></use>
      </pattern>
      <pattern
        id="G"
        width="512"
        height="512"
        x="-441"
        y="-387"
        patternUnits="userSpaceOnUse"
      >
        <use xlinkHref="#h"></use>
      </pattern>
      <pattern
        id="O"
        width="512"
        height="512"
        x="-512"
        y="-512"
        patternUnits="userSpaceOnUse"
      >
        <use xlinkHref="#i"></use>
      </pattern>
      <radialGradient
        id="N"
        cx="86.601%"
        cy="84.21%"
        r="62.398%"
        fx="86.601%"
        fy="84.21%"
      >
        <stop offset="0%" stopColor="#018137"></stop>
        <stop offset="100%" stopColor="#007E35" stopOpacity="0"></stop>
      </radialGradient>
      <path
        id="T"
        d="M25 73.107L41.756 46.96 26.572 23 38.144 23 47.977 41.099 57.61 23 69.082 23 53.83 47.336 70.587 73.107 58.647 73.107 47.777 53.778 36.873 73.107z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(48)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a"></use>
        </mask>
        <g mask="url(#b)">
          <use fill="url(#c)" xlinkHref="#d"></use>
          <use fill="url(#e)" fillOpacity="0.012" xlinkHref="#d"></use>
          <use fill="#000" filter="url(#f)" xlinkHref="#d"></use>
        </g>
        <g mask="url(#b)">
          <use fill="url(#g)" xlinkHref="#h"></use>
          <use fill="url(#i)" fillOpacity="0.012" xlinkHref="#h"></use>
          <use fill="#000" filter="url(#j)" xlinkHref="#h"></use>
        </g>
        <g mask="url(#b)">
          <use fill="url(#k)" xlinkHref="#l"></use>
          <use fill="url(#m)" fillOpacity="0.012" xlinkHref="#l"></use>
          <use fill="#000" filter="url(#n)" xlinkHref="#l"></use>
        </g>
        <g mask="url(#b)">
          <use fill="url(#o)" xlinkHref="#p"></use>
          <use fill="url(#q)" fillOpacity="0.012" xlinkHref="#p"></use>
          <use fill="#000" filter="url(#r)" xlinkHref="#p"></use>
        </g>
        <g mask="url(#b)">
          <use fill="url(#s)" xlinkHref="#t"></use>
          <use fill="url(#u)" fillOpacity="0.012" xlinkHref="#t"></use>
          <use fill="#000" filter="url(#v)" xlinkHref="#t"></use>
        </g>
        <g mask="url(#b)">
          <use fill="url(#w)" xlinkHref="#x"></use>
          <use fill="url(#y)" fillOpacity="0.012" xlinkHref="#x"></use>
          <use fill="#000" filter="url(#z)" xlinkHref="#x"></use>
        </g>
        <g mask="url(#b)">
          <use fill="url(#A)" xlinkHref="#B"></use>
          <use fill="url(#C)" fillOpacity="0.012" xlinkHref="#B"></use>
          <use fill="#000" filter="url(#D)" xlinkHref="#B"></use>
        </g>
        <g mask="url(#b)">
          <use fill="url(#E)" xlinkHref="#F"></use>
          <use fill="url(#G)" fillOpacity="0.012" xlinkHref="#F"></use>
          <use fill="#000" filter="url(#H)" xlinkHref="#F"></use>
        </g>
      </g>
      <g transform="translate(48)">
        <mask id="K" fill="#fff">
          <use xlinkHref="#I"></use>
        </mask>
        <path
          fill="#000"
          fillOpacity="0.1"
          d="M-40 37h80a8 8 0 018 8v80c0 4.418-13.582 18-18 18h-80a8 8 0 01-8-8V55c0-4.418 13.582-18 18-18z"
          filter="url(#J)"
          mask="url(#K)"
        ></path>
      </g>
      <g transform="translate(0 35)">
        <mask id="P" fill="#fff">
          <use xlinkHref="#L"></use>
        </mask>
        <use fill="url(#M)" xlinkHref="#L"></use>
        <use fill="url(#N)" xlinkHref="#L"></use>
        <use fill="url(#O)" fillOpacity="0.013" xlinkHref="#L"></use>
        <g fill="#000" mask="url(#P)">
          <use filter="url(#Q)" xlinkHref="#R"></use>
        </g>
        <g mask="url(#P)">
          <use fill="#000" filter="url(#S)" xlinkHref="#T"></use>
          <use fill="url(#U)" xlinkHref="#T"></use>
        </g>
      </g>
    </g>
  </svg>
  )
}

export const RejectIcon = () => {
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
    
      viewBox="0 0 32 32"
    >
      <path d="M27.314 4.686C24.292 1.664 20.274 0 16 0S7.708 1.664 4.686 4.686C1.664 7.708 0 11.726 0 16s1.664 8.292 4.686 11.314C7.708 30.336 11.726 32 16 32s8.292-1.664 11.314-4.686C30.336 24.292 32 20.274 32 16s-1.664-8.292-4.686-11.314zM28 16c0 2.588-.824 4.987-2.222 6.949L9.051 6.222A11.93 11.93 0 0116 4c6.617 0 12 5.383 12 12zM4 16c0-2.588.824-4.987 2.222-6.949l16.727 16.727A11.93 11.93 0 0116 28C9.383 28 4 22.617 4 16z"></path>
  </svg>
  )
}
export const VerifiedIcon = () => {
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      
      viewBox="0 0 24 24"
    >
      <path d="M23.016 12l-2.438-2.813.328-3.656-3.609-.844-1.875-3.188L12 2.952 8.578 1.499 6.703 4.687l-3.609.797.328 3.703L.984 12l2.438 2.813-.328 3.656 3.609.844 1.875 3.188L12 21.048l3.422 1.453 1.875-3.188 3.609-.844-.328-3.656zm-12.938 4.734L6.281 12.89l1.5-1.453 2.297 2.344 5.859-5.906 1.5 1.5z"></path>
    </svg>
  )
}
export const EditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M11 3H4a2.997 2.997 0 00-3 3v14a2.997 2.997 0 003 3h14a2.997 2.997 0 003-3v-7a1 1 0 00-2 0v7c0 .276-.111.525-.293.707S18.276 21 18 21H4c-.276 0-.525-.111-.707-.293S3 20.276 3 20V6c0-.276.111-.525.293-.707S3.724 5 4 5h7a1 1 0 000-2zm6.793-1.207l-9.5 9.5a.992.992 0 00-.263.465l-1 4a1 1 0 001.213 1.213l4-1c.167-.041.33-.129.465-.263l9.5-9.5c.609-.609.914-1.41.914-2.207s-.305-1.598-.914-2.207a3.119 3.119 0 00-4.415-.001zm1.414 1.414c.219-.219.504-.328.793-.328s.574.109.793.328.328.504.328.793-.109.574-.328.793l-9.304 9.304-2.114.529.529-2.114z"></path>
    </svg>
  );
};

export const NotFoundIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M16 2.899l13.409 26.726H2.59L15.999 2.899zM16 0c-.69 0-1.379.465-1.903 1.395L.438 28.617C-.608 30.477.282 32 2.416 32h27.166c2.134 0 3.025-1.522 1.978-3.383L17.901 1.395C17.378.465 16.688 0 15.998 0z"></path>
      <path d="M18 26a2 2 0 11-3.999.001A2 2 0 0118 26zM16 22a2 2 0 01-2-2v-6a2 2 0 114 0v6a2 2 0 01-2 2z"></path>
    </svg>
  );
};

export const MagnifyingGlassIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M31.008 27.231l-7.58-6.447c-.784-.705-1.622-1.029-2.299-.998a11.954 11.954 0 002.87-7.787c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c2.972 0 5.691-1.081 7.787-2.87-.031.677.293 1.515.998 2.299l6.447 7.58c1.104 1.226 2.907 1.33 4.007.23s.997-2.903-.23-4.007zM12 20a8 8 0 110-16 8 8 0 010 16z"></path>
    </svg>
  );
};
