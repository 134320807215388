import http from "../http-common";

export const getAllInstitutionsForAdmin = (token) => {
  const url = "folder/getAllInstutionDocumentsForAdmin";
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return http
    .get(url, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error.message);
      // throw error;
    });
};

export const getAllInstitutionsFodersAndDocs = (token) => {
  const url = "folder/getAllInstitutionFolderAndDocumentsForAdmin";
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return http
    .get(url, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error.message);
      // throw error;
    });
};

// export const getSingleInstitionFolders = async (id) => {
//   const data = await http
//     .get(`folder/getSingleInstitutionFolders/${id}`)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((err) => {
//       return err.response;
//     });

//   return data;
// };

export const getSingleInstitionFolders = async (id) => {
  const data = await http
    .get(`/institution/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

export const getInstitutionFile = async (id) => {
  const data = await http
    .get(`folder/getFolderDocuments/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};
