import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  document: {
    comments: [],
    documentLetter: [],
  },
  pending: false,
  error: false,
  errorMsg: "",
};

const singleDocumentSlice = createSlice({
  name: "singleDocument",
  initialState,
  reducers: {
    handleSingleDocumentRequest: (state) => {
      state.pending = true;
    },
    getSingleDocumentSuccess: (state, action) => {
      state.document = action.payload;
      state.pending = false;
    },
    handleSingleDocumentError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
    addCommentToLetterSuccess: (state, action) => {
      const newComment = action.payload;
      if (!state.document.comments) {
        state.document.comments = [];
      }
      state.document.comments = [newComment, ...state.document.comments];
    },

    addLetterSuccess: (state, action) => {
      const newLetter = action.payload;
      console.log(newLetter);
      if (!state.document.comments) {
        state.document.comments = [];
      }
      // console.log(current(state));
      state.document.documentLetter = [
        newLetter,
        ...state.document.documentLetter,
      ];
    },
    updateLetterSuccess: (state, action) => {
      const updatedLetter = action.payload;
      const index = state.document?.documentLetter?.findIndex(
        (letter) => letter.id === updatedLetter.id
      );

      if (index !== -1) {
        state.document.documentLetter[index] = updatedLetter;
      }
    },
    addDocumentRecordsSuccess: (state, action) => {
      const newRecords = action.payload;
      state.document.documentRecords = newRecords;
    },
  },
});

export const {
  handleSingleDocumentRequest,
  addLetterSuccess,
  getSingleDocumentSuccess,
  addCommentToLetterSuccess,
  addDocumentRecordsSuccess,
  deleteCommentSuccess,
  updateLetterSuccess,
  handleSingleDocumentError,
} = singleDocumentSlice.actions;

export default singleDocumentSlice.reducer;
