import { createSlice } from "@reduxjs/toolkit";

export const procurementMethodSlice = createSlice({
  name: "procurementMethods",
  initialState: {
    procurementMethods: [],
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleProcurementMethodRequest: (state) => {
      state.pending = true;
    },
    createProcurementMethodSuccess: (state) => {
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },
    getProcurementMethodSuccess: (state, action) => {
      state.pending = false;
      state.procurementMethods = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    updateProcurementForm: (state, action) => {
      state.pending = false;
      state.procurementMethods = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    deleteProcurementForm: (state, action) => {
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },
    handleProcurementMethodError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
  },
});

export const {
  handleProcurementMethodRequest,
  createProcurementMethodSuccess,
  getProcurementMethodSuccess,
  handleProcurementMethodError,
  updateProcurementForm,
  deleteProcurementForm,
} = procurementMethodSlice.actions;
export default procurementMethodSlice.reducer;
