import React, { useEffect } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { printPlugin } from "@react-pdf-viewer/print";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import packageJson from "./../../../../package.json";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";
import pdfFile from "../../../images/report.pdf";
const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];

const DocumentPDFViewer = ({ data }) => {
  const printPluginInstance = printPlugin();
  const { print } = printPluginInstance;

  return (
    <Worker
      workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
    >
      <button
        onClick={print}
        style={{
          border: "0",
          background: "inherit",
          color: "darkblue",
          display: "flex",
          marginLeft: "auto",
          marginBottom: "10px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 text-dark"
          style={{ display: "block", width: "25px", height: "25px" }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
            style={{ color: "#4D06A5" }}
          />
        </svg>
      </button>
      <div
        style={{
          border: "1px solid rgba(0, 0, 0, 0.3)",
          height: "800px",
        }}
      >
        {data ? (
          <Viewer fileUrl={data} plugins={[printPluginInstance]} />
        ) : (
          <Viewer fileUrl={pdfFile} plugins={[printPluginInstance]} />
        )}
      </div>
    </Worker>
  );
};

export default DocumentPDFViewer;
