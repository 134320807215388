import http from "../http-common";
import {
  handleAuditTrailsRequest,
  getAuditTrailsSuccess,
  handleAuditTrailsError,
} from "../redux/Slices/AuditTrailSlice";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const getAuditTrails = (dispatch) => {
  dispatch(handleAuditTrailsRequest());
  http
    .get(`/log/`)
    .then((response) => {
      dispatch(getAuditTrailsSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleAuditTrailsError());
      toast.error(error.response.data.message);
    });
};
