import React, { Fragment, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Modal, Button, Card } from "react-bootstrap";
import {
  AddBusinessRegistrationSchema,
  AddLetterSchema,
  AddMessageSchema,
} from "../../../Validations/AddLetterValidations";
import { addMessage } from "../../../services/messageService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Loading from "../Backdrop/LoadingSpinner";
import { addLetter } from "../../../services/lettersService";
import { addDocumentLetterSuccess } from "../../../redux/Slices/DocumentSlice";
import {
  addBusinessRegistrationMessage,
  addLetterToDocument,
} from "../../../services/documentsService";

const AddBusinessLetterModal = ({ document_id, show, close, businessData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { pending } = useSelector((state) => state.letters);

  const [letters, setLetters] = useState({
    message: "",
    businessClientId: businessData?.businessClientId,
    businessRegistrationId: businessData?.id,
  });

  return (
    <Modal className="fade " size="lg" show={show} onHide={() => close()}>
      <Modal.Header>
        <Modal.Title>Add Business Registration Message</Modal.Title>
        <Button
          variant=""
          className="btn-close"
          onClick={() => close()}
        ></Button>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Card.Body>
              <Formik
                initialValues={letters}
                validationSchema={AddBusinessRegistrationSchema}
                onSubmit={async (values) => {
                  try {
                    setLoading(true);
                    const formData = {
                      ...values,
                      businessClientId: businessData?.businessClientId,
                      businessRegistrationId: businessData?.id,
                    };
                    await addBusinessRegistrationMessage(
                      formData,
                      dispatch,
                      history
                    );
                    setLoading(false);
                    close();
                    // window.location.reload();
                  } catch (error) {
                    console.error(error);
                    setLoading(false);
                    close();
                  }
                }}
              >
                {(formik) => {
                  const {
                    errors,
                    touched,
                    isValid,
                    dirty,
                    handleChange,
                    handleBlur,
                    values,
                  } = formik;

                  return (
                    <Form className="basic-form" style={{ marginTop: "30px" }}>
                      <div className="row">
                        <div className="col-xl-12 col-lg-12">
                          <div className="basic-form">
                            <div className="row">
                              <div className="form-group mb-3 col-md-12">
                                <label htmlFor="" className="text-dark">
                                  Message
                                </label>

                                <textarea
                                  name="message"
                                  id="message"
                                  className="form-control"
                                  onChange={handleChange}
                                  cols="30"
                                  rows="8"
                                ></textarea>
                                <ErrorMessage
                                  name="message"
                                  component="span"
                                  className="text-danger"
                                  style={{ color: "#4D06A5" }}
                                />
                              </div>

                              <div className="form-group">
                                <button
                                  type="submit"
                                  className={
                                    !(dirty && isValid)
                                      ? " btn btn-sm mt-3  disabled-btn text-white"
                                      : "btn btn-sm mt-3 text-white "
                                  }
                                  style={{ background: "#4D06A5" }}
                                  disabled={!(dirty && isValid)}
                                >
                                  Add letter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Card.Body>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddBusinessLetterModal;
