import http from "../http-common";
import {
  handleMessageRequest,
  createMessageSuccess,
  getMessageSuccess,
  handleMessageError,
} from "../redux/Slices/messageSlice";
import { toast } from "react-toastify";

// Business Messages

export const addMessage = (data, dispatch) => {
  dispatch(handleMessageRequest());

  http
    .post("/businessMessage/", data)
    .then((response) => {
      dispatch(createMessageSuccess());
      toast.success("message created successfully");
      window.location.reload();
    })
    .catch((error) => {
      toast.error("Error Creating message");
      dispatch(handleMessageError());
      console.error(error);
    });
};

export const getMessages = (dispatch) => {
  dispatch(handleMessageRequest());

  http
    .get("/businessMessage/")
    .then((response) => {
      dispatch(getMessageSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleMessageError());
      toast.error(error.response.data.message);
    });
};

// Client Messages

export const addClientMessage = (formData, dispatch, token) => {
  dispatch(handleMessageRequest());
  const header = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  http
    .post("/clientMessage/", formData, header)
    .then((response) => {
      dispatch(createMessageSuccess());
      toast.success("message created successfully");
      // window.location.reload();
    })
    .catch((error) => {
      toast.error("Error Creating message");
      dispatch(handleMessageError());
      console.error(error);
    });
};

export const getClientMessages = async (setMessages) => {
  const authToken = localStorage.getItem("token");
  const data = await http
    .get("/clientMessage", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then((res) => {
      // setLoading(false);
      console.log(res.data.data);
      setMessages(res.data.data);
    })
    .catch((e) => {
      // setLoading(false);
      // console.log(e);
    });

  return data;
};
