import { createSlice } from "@reduxjs/toolkit";

export const documentSlice = createSlice({
  name: "documents",
  initialState: {
    documents: [],
    allAssignedDocuments: [],
    recordDocuments: [],
    complianceDocuments: [],
    directorDocuments: [],
    assignedDocuments: [],
    complianceAssignedDocuments: [],
    unassignedDocuments: [],
    approvedDocuments: [],
    rejectedDocuments: [],
    documentCounts: null,
    documentsChartData: null,
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleDocumentRequest: (state) => {
      state.pending = true;
    },
    createDocumentSuccess: (state) => {
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },
    updateDocumentState: (state, action) => {
      removeObjectWithId(state.assignedDocuments, action.payload);
    },
    updateUnAssignedDocumentState: (state, action) => {
      removeObjectWithId(state.directorDocuments, action.payload);
    },
    getDocumentSuccess: (state, action) => {
      state.pending = false;
      state.documents = action.payload;
      state.error = false;
      state.errorMsg = "";
    },

    getAllAssignedDocumentSuccess: (state, action) => {
      state.pending = false;
      state.documents = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    updateDocumentSuccess: (state, action) => {
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },
    getRecordDocumentSuccess: (state, action) => {
      state.pending = false;
      state.recordDocuments = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    updateRecordDocumentSuccess: (state, action) => {
      state.pending = false;
      state.recordDocuments = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getComplainceDocumentSuccess: (state, action) => {
      state.pending = false;
      state.complianceDocuments = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getDirectorDocumentSuccess: (state, action) => {
      state.pending = false;
      state.directorDocuments = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getAssignedDocumentsSuccess: (state, action) => {
      state.pending = false;
      state.assignedDocuments = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    addAssignedDocuments: (state, action) => {
      state.pending = false;
      state.assignedDocuments = [...state.assignedDocuments, action.payload];
      state.error = false;
      state.errorMsg = "";
    },
    getComplianceAssignedDocumentsSuccess: (state, action) => {
      state.pending = false;
      state.complianceAssignedDocuments = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getUnassignedDocumentSuccess: (state, action) => {
      state.pending = false;
      state.unassignedDocuments = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getApprovedDocumentSuccess: (state, action) => {
      state.pending = false;
      state.approvedDocuments = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getRejectedDocumentSuccess: (state, action) => {
      state.pending = false;
      state.rejectedDocuments = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getDocumentCountsSuccess: (state, action) => {
      state.pending = false;
      state.documentCounts = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getDocumentsChartSuccess: (state, action) => {
      state.pending = false;
      state.documentsChartData = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    handleDocumentError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },

    addDocumentLetterSuccess: (state, action) => {
      state.pending = false;
      state.documents.data.documentLetter.push(action.payload);
      state.error = false;
      state.errorMsg = "";
    },
  },
});

export const {
  addAssignedDocuments,
  handleDocumentRequest,
  createDocumentSuccess,
  getDocumentSuccess,
  getAllAssignedDocumentSuccess,
  getRecordDocumentSuccess,
  getComplainceDocumentSuccess,
  getDirectorDocumentSuccess,
  getAssignedDocumentsSuccess,
  getComplianceAssignedDocumentsSuccess,
  getUnassignedDocumentSuccess,
  getApprovedDocumentSuccess,
  getRejectedDocumentSuccess,
  getDocumentCountsSuccess,
  getDocumentsChartSuccess,
  handleDocumentError,
  addDocumentLetterSuccess,
  updateRecordDocumentSuccess,
  updateDocumentSuccess,
  updateDocumentState,
  updateUnAssignedDocumentState,
} = documentSlice.actions;
export default documentSlice.reducer;

function removeObjectWithId(arr, id) {
  const objWithIdIndex = arr.findIndex((obj) => obj.id === id);

  if (objWithIdIndex > -1) {
    arr.splice(objWithIdIndex, 1);
  }

  return arr;
}

// {
//   "success": true,
//   "data": {
//       "id": "4b49a394-25f7-48df-a31e-f05b021d5cb8",
//       "status": "new",
//       "institutionId": "100",
//       "categoryFolderId": "0e4ab687-6e53-4e77-9bb0-5378351a6a39",
//       "fromLocation": "senior-management",
//       "department": "records",
//       "category": "bid submission",
//       "parentId": null,
//       "userAssignedId": null,
//       "assignedById": null,
//       "TRNo": "TR 1/23",
//       "recordReference": "RRN0001",
//       "TID": "TID0001",
//       "documentTitle": "Tender - Toyota land cruiser for Director of finance",
//       "institution": "Governor CRR",
//       "invitee": null,
//       "inviteDateAndTime": null,
//       "sourceOfFunding": "Donor",
//       "procurementMethod": "Request For Proposal",
//       "justificationOfMethod": "Toyota Test",
//       "dispatch": null,
//       "budgetEstimate": 100000,
//       "docParentId": null,
//       "uploadDate": "2023-11-08T17:10:59.000Z",
//       "uploadedBy": "David Oluwaseun Ladipo",
//       "createdAt": "2023-11-08T19:27:12.000Z",
//       "updatedAt": "2023-11-09T12:24:26.000Z",
//       "categoryFolder": {
//           "id": "0e4ab687-6e53-4e77-9bb0-5378351a6a39",
//           "name": "bid submission",
//           "description": null,
//           "folderYearId": "93ab3b8b-78b3-441c-8945-b9ccb853934a",
//           "createdAt": "2023-11-08T19:18:49.000Z",
//           "updatedAt": "2023-11-08T19:18:49.000Z"
//       },
//       "assignByUser": null,
//       "files": [
//           {
//               "id": "60abc472-810c-43c0-bc32-263698b457ee",
//               "folderDocumentId": "4b49a394-25f7-48df-a31e-f05b021d5cb8",
//               "description": null,
//               "path": "1699471633353_ipay arcitecture sample.pdf",
//               "fileName": "ipay arcitecture sample.pdf",
//               "type": "file",
//               "lots": "",
//               "parentId": null,
//               "createdAt": "2023-11-08T19:27:13.000Z",
//               "updatedAt": "2023-11-08T19:27:13.000Z",
//               "children": []
//           },
//           {
//               "id": "bcd9e7f1-7e18-4c49-9f6e-5cdf38fc089c",
//               "folderDocumentId": "4b49a394-25f7-48df-a31e-f05b021d5cb8",
//               "description": null,
//               "path": "1699471633353_ipay arcitecture sample.pdf",
//               "fileName": "ipay arcitecture sample.pdf",
//               "type": "file",
//               "lots": "",
//               "parentId": null,
//               "createdAt": "2023-11-08T19:27:13.000Z",
//               "updatedAt": "2023-11-08T19:27:13.000Z",
//               "children": []
//           }
//       ],
//       "lots": [
//           {
//               "id": "52ec3dc2-16b5-4664-9951-ec1ba2552b0a",
//               "name": "Mechanical",
//               "folderDocumentId": "4b49a394-25f7-48df-a31e-f05b021d5cb8",
//               "createdAt": "2023-11-08T19:27:13.000Z",
//               "updatedAt": "2023-11-08T19:27:13.000Z"
//           },
//           {
//               "id": "fb66535c-7b13-4dff-b4df-98f087da2614",
//               "name": "Electrical",
//               "folderDocumentId": "4b49a394-25f7-48df-a31e-f05b021d5cb8",
//               "createdAt": "2023-11-08T19:27:13.000Z",
//               "updatedAt": "2023-11-08T19:27:13.000Z"
//           }
//       ],
//       "documentLetter": [
//           {
//               "id": "074e365e-b8d8-40df-877d-466c5f645970",
//               "documentId": "4b49a394-25f7-48df-a31e-f05b021d5cb8",
//               "userId": "12622467",
//               "title": "hi ",
//               "content": "just checking!!",
//               "signature": null,
//               "createdAt": "2023-11-09T14:32:46.000Z",
//               "updatedAt": "2023-11-10T06:42:56.000Z"
//           },
//           {
//               "id": "347c4d31-1bf7-4a23-a430-8772d48b7f9a",
//               "documentId": "4b49a394-25f7-48df-a31e-f05b021d5cb8",
//               "userId": "12622468",
//               "title": "Approval of bid submission",
//               "content": "we tend to make sure blla bla bla dkjdnknfkd fjnfkndk fnidnkndknd jsknkdsn. sfnksfksf sjfhskhfsfo\n\nssfisjfksmdf\nsfjosjfosj\nspjfosjf",
//               "signature": "director_126224681690199563056.png",
//               "createdAt": "2023-11-09T10:11:30.000Z",
//               "updatedAt": "2023-11-09T10:32:12.000Z"
//           },
//       ],
//       "documentMessages": [],
//       "procurementDocument": [
//           {
//               "id": "ad285a52-bfa4-4d68-bc03-5d7c61bb2a99",
//               "documentId": "4b49a394-25f7-48df-a31e-f05b021d5cb8",
//               "procuringOrganization": "",
//               "procurementDescription": "",
//               "procurementMethods": "",
//               "justificationMethod": "",
//               "budget": "",
//               "trNo": "",
//               "observation": "dd",
//               "recommendation": "Resubmission",
//               "conclusion": "ass",
//               "createdAt": "2023-11-09T10:05:08.000Z",
//               "updatedAt": "2023-11-09T10:05:08.000Z",
//               "procurementBid": [
//                   {
//                       "id": "9e364be6-1d59-4685-8238-3c420bacb462",
//                       "procurementId": "ad285a52-bfa4-4d68-bc03-5d7c61bb2a99",
//                       "biders": "so",
//                       "bidPrice": 233,
//                       "bidRemark": "sdd",
//                       "createdAt": "2023-11-09T10:05:09.000Z",
//                       "updatedAt": "2023-11-09T10:05:09.000Z"
//                   },
//                   {
//                       "id": "4ecfb091-e009-4c02-8285-c4198111fed8",
//                       "procurementId": "ad285a52-bfa4-4d68-bc03-5d7c61bb2a99",
//                       "biders": "okay",
//                       "bidPrice": 23,
//                       "bidRemark": "wee",
//                       "createdAt": "2023-11-09T10:05:09.000Z",
//                       "updatedAt": "2023-11-09T10:05:09.000Z"
//                   }
//               ],
//               "procurementSections": [
//                   {
//                       "id": "de477841-3ea0-43fe-8df7-99e2fa83818a",
//                       "name": "Signed Minutes of contracts Commitee",
//                       "status": "",
//                       "procurementId": "ad285a52-bfa4-4d68-bc03-5d7c61bb2a99",
//                       "reference": "",
//                       "procurementSectionRemark": "",
//                       "createdAt": "2023-11-09T10:05:09.000Z",
//                       "updatedAt": "2023-11-09T10:05:09.000Z"
//                   },
//                   {
//                       "id": "c475861e-1668-43e0-a353-c9279fd81ad0",
//                       "name": "Satisfy condition/s for use of the method",
//                       "status": "no",
//                       "procurementId": "ad285a52-bfa4-4d68-bc03-5d7c61bb2a99",
//                       "reference": "",
//                       "procurementSectionRemark": "dd",
//                       "createdAt": "2023-11-09T10:05:09.000Z",
//                       "updatedAt": "2023-11-09T10:05:09.000Z"
//                   },

//               ]
//           }
//       ],
//       "parent": null,
//       "documentRecords": {
//           "id": "55dffe3c-3ba5-4e8b-9edb-b8bbab3deb28",
//           "documentId": "4b49a394-25f7-48df-a31e-f05b021d5cb8",
//           "institution": "Governor CRR",
//           "project": "dde",
//           "nameOfSupplier": "ddd",
//           "itemProcured": "ddd",
//           "trNo": "TR1149465",
//           "proMethod": "Request For Quotation",
//           "justificationForMethod": "Toyota Test",
//           "suppliersGender": "male",
//           "dateOfSubmission": "2023-11-16T00:00:00.000Z",
//           "currencyType": "dollar",
//           "currencyAmount": 33,
//           "totalInDalasis": 33,
//           "initialEst": 3,
//           "approvedAmount": 3,
//           "approvedDate": "2023-11-09T00:00:00.000Z",
//           "remarks": "eee",
//           "createdAt": "2023-11-08T19:32:52.000Z",
//           "updatedAt": "2023-11-09T11:51:27.000Z"
//       },
//       "comments": [
//           {
//               "id": "f82d2816-f366-4003-8db4-b85e46700381",
//               "documentId": "4b49a394-25f7-48df-a31e-f05b021d5cb8",
//               "userId": "12622467",
//               "comment": "sup just checkiong",
//               "createdAt": "2023-11-09T10:18:20.000Z",
//               "updatedAt": "2023-11-09T10:18:20.000Z",
//               "userComment": {
//                   "firstName": "Phoday",
//                   "middleName": "M.",
//                   "lastName": "Jaiteh"
//               }
//           },
//           {
//               "id": "eed81da7-1fde-48db-af6b-72ff16804617",
//               "documentId": "4b49a394-25f7-48df-a31e-f05b021d5cb8",
//               "userId": "12622467",
//               "comment": "okayy",
//               "createdAt": "2023-11-09T09:47:17.000Z",
//               "updatedAt": "2023-11-09T09:47:17.000Z",
//               "userComment": {
//                   "firstName": "Phoday",
//                   "middleName": "M.",
//                   "lastName": "Jaiteh"
//               }
//           },

//       ]
//   }
// }
