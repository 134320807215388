import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { addRecordDocument } from "../../../services/documentsService";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "../Backdrop/LoadingSpinner";
import { AddRecordSchema } from "../../../Validations/AddRecordValidations";
import { getProcurementMethod } from "../../../services/procurementMethodService";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import LoadingSpinner from "../Backdrop/LoadingSpinner";

export default function AddRecord({ document_id, document, close, show }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProcurementMethod(dispatch);
  }, [document_id]);

  const { pending, procurementMethods } = useSelector(
    (state) => state.procurementMethods
  );

  const [records, setRecords] = useState({
    documentId: document_id,
    institution: document?.institution,
    project: document?.documentTitle,
    // nameOfSupplier: "",
    itemProcured: "",
    proMethod: document?.procurementMethod || "",
    justificationForMethod: document?.justificationOfMethod || "",
    suppliersGender: "",
    dateOfSubmission: "12/10/2023",
    currencyType: "",
    currencyAmount: 0,
    totalInDalasis: 0,
    initialEst: 0,
    approvedAmount: 0,
    approvedDate: "12/10/2023",
    remarks: "",
  });

  if (loading) {
    return <Loading />;
  }

  // if (pending) {
  //   return <Loading />;
  // }

  return (
    <Modal className="fade" size="xl" show={show} onHide={() => close()}>
      <Modal.Header>
        <Modal.Title>
          <h3>Add Record</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Formik
            initialValues={records}
            validationSchema={AddRecordSchema}
            onSubmit={async (values) => {
              try {
                setLoading(true);
                const formData = {
                  ...values,
                  institution: document?.institution,
                };
                addRecordDocument(formData, dispatch, history);
                setLoading(false);
                close();
                // window.location.reload();
              } catch (error) {
                console.error(error);
                toast.error("error adding record");
                setLoading(false);
              }
            }}
          >
            {(formik) => {
              const {
                errors,
                touched,
                isValid,
                dirty,
                handleChange,
                handleBlur,
                values,
              } = formik;

              return (
                <Form className="basic-form" style={{ marginTop: "10px" }}>
                  <div className="row">
                    <div className="col-xl-12 col-lg-12">
                      <div className="basic-form">
                        <div className="row">
                          <div
                            style={{ display: "flex", justifyContent: "space" }}
                          >
                            {/* <div className="form-group mb-3 col-md-6">
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Project
                                </label>
                                <Field
                                  type="text"
                                  name="project"
                                  id="project"
                                  className={
                                    errors.project && touched.project
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="project"
                                  component="span"
                                  className="text-danger"
                                  style={{ color: "#4D06A5" }}
                                />
                              </div>
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Name of supplier
                                </label>
                                <Field
                                  type="text"
                                  name="nameOfSupplier"
                                  id="nameOfSupplier"
                                  className={
                                    errors.nameOfSupplier &&
                                    touched.nameOfSupplier
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="nameOfSupplier"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Item Procured
                                </label>
                                <Field
                                  type="text"
                                  name="itemProcured"
                                  id="itemProcured"
                                  className={
                                    errors.itemProcured && touched.itemProcured
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="itemProcured"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Pro. Method
                                </label>
                                <Field
                                  as="select"
                                  type="text"
                                  name="proMethod"
                                  id="proMethod"
                                  className={
                                    errors.proMethod && touched.proMethod
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                >
                                  <option value="">
                                    Select Procurment Method
                                  </option>
                                  {procurementMethods?.length >= 1 ? (
                                    procurementMethods?.map((item, index) => {
                                      const { method, abbreviation } = item;
                                      return (
                                        <option value={method} key={index}>
                                          {method}
                                        </option>
                                      );
                                    })
                                  ) : (
                                    <span>
                                      <strong>no procurement</strong>
                                    </span>
                                  )}
                                </Field>

                                <ErrorMessage
                                  name="proMethod"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Justification for method
                                </label>
                                <Field
                                  type="text"
                                  name="justificationForMethod"
                                  id="justificationForMethod"
                                  className={
                                    errors.justificationForMethod &&
                                    touched.justificationForMethod
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="justificationForMethod"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Supplier's gender
                                </label>
                                <Field
                                  as="select"
                                  type="text"
                                  name="suppliersGender"
                                  id="suppliersGender"
                                  className={
                                    errors.suppliersGender &&
                                    touched.suppliersGender
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                >
                                  <option value="">Select gender</option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                </Field>
                                <ErrorMessage
                                  name="suppliersGender"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Date of submission
                                </label>
                                <Field
                                  type="date"
                                  name="dateOfSubmission"
                                  id="dateOfSubmission"
                                  className={
                                    errors.dateOfSubmission &&
                                    touched.dateOfSubmission
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="dateOfSubmission"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                            </div> */}

                            <div className="form-group mb-3 col-md-6">
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Currency Type
                                </label>
                                <Field
                                  type="text"
                                  as="select"
                                  name="currencyType"
                                  id="currencyType"
                                  className={
                                    errors.currencyAmount &&
                                    touched.currencyAmount
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                >
                                  <option value="">Select current type</option>
                                  <option value="dalasi">Dalasis</option>
                                  <option value="dollar">Dollar</option>
                                  <option value="euro">Euro</option>
                                  <option value="pound">Pound</option>
                                  <option value="cfa">CFA</option>
                                </Field>
                                <ErrorMessage
                                  name="currencyType"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Currency Amount
                                </label>
                                <Field
                                  type="text"
                                  name="currencyAmount"
                                  id="currencyAmount"
                                  className={
                                    errors.currencyAmount &&
                                    touched.currencyAmount
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="currencyAmount"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>

                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Total in Dalasis
                                </label>
                                <Field
                                  type="text"
                                  name="totalInDalasis"
                                  id="totalInDalasis"
                                  className={
                                    errors.totalInDalasis &&
                                    touched.totalInDalasis
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="totalInDalasis"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Initial Est
                                </label>
                                <Field
                                  type="text"
                                  name="initialEst"
                                  id="initialEst"
                                  className={
                                    errors.initialEst && touched.initialEst
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="initialEst"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Approved Amount
                                </label>
                                <Field
                                  type="text"
                                  name="approvedAmount"
                                  id="approvedAmount"
                                  className={
                                    errors.approvedAmount &&
                                    touched.approvedAmount
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="approvedAmount"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                              <div className="form-control-sm">
                                <label htmlFor="" className="text-dark">
                                  Approved Date
                                </label>
                                <Field
                                  type="date"
                                  name="approvedDate"
                                  id="approvedDate"
                                  className={
                                    errors.approvedDate && touched.approvedDate
                                      ? "input-error form-control form-control-sm"
                                      : "form-control form-control-sm"
                                  }
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="approvedDate"
                                  component="span"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-control-sm">
                            <label htmlFor="" className="text-dark">
                              Remarks
                            </label>
                            <textarea
                              name="remarks"
                              id="remarks"
                              className="form-control"
                              onChange={handleChange}
                              cols="30"
                              rows="8"
                            ></textarea>
                          </div>

                          <div className="form-group">
                            <button
                              type="submit"
                              className={
                                !(dirty && isValid)
                                  ? " btn btn-sm mt-3  disabled-btn text-white"
                                  : "btn btn-sm mt-3 text-white "
                              }
                              style={{ background: "#4D06A5" }}
                              disabled={!(dirty && isValid)}
                            >
                              Add Record
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
}
