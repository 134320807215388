import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddLetterModal from "./AddLetterModal";
import { useState } from "react";
import { getMessages } from "../../../services/messageService";
import "./letter.scss";
import Available from "../Dashboard/Room/Available";
import AddBusinessLetterModal from "./AddBusinessMessage";
export default function LetterData({ document_id, auth, business }) {
  console.log("document_id:", document_id, "business:", business);

  const handleLetterModal = () => {
    setShowLetter(!showLetter);
  };

  //API CALL
  console.log(business)

  const [showLetter, setShowLetter] = useState(false);

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  return (
    <>
      <button
        className="btn btn-seconary btn-sm btn-xs"
        style={{
          background: "#4D06A5",
          color: "#fff",
          display: "flex",
          marginLeft: "auto",
          marginBottom: "20px",
        }}
        onClick={() => handleLetterModal()}
      >
        Add Message
      </button>

      <div className="message-container">
        {
        !business?.businessRegistrationMessages ||
        business?.businessRegistrationMessages?.length === 0 ? (
          <span className="no-message">No Messages Available.</span>
        ) : (
          business?.businessRegistrationMessages?.map((text) => {
            return (
              <div className="message-body">
                <div className="message-body__title">{text?.title}</div>
                <div className="message-body__message">{text?.message}</div>
                <span className="message-body__date">
                  {formatCreatedAt(text?.createdAt)}
                </span>
              </div>
            );
          })
        )
        }
      </div>

      {console.log("model business:", business)}
      <AddBusinessLetterModal
        document_id={document_id}
        businessData={business}
        // businessClientId={businessClientId}

        show={showLetter}
        close={handleLetterModal}
      />
    </>
  );
}
