import React, { useEffect, useState } from "react";
import {
  getAllDocumentStatusDispatch,
  updateDispatchDocumentStatus,
  getSinglePDFDocument,
} from "../../../services/documentsService";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Backdrop/LoadingSpinner";
import Paginate from "react-paginate";
import moment from "moment";
import { Link } from "react-router-dom";
import { MagnifyingGlassIcon } from "../../../icons/icons";
import folderIcon from "../../../images/svg/folder.svg";
import "./ReadyForDispatch.scss";
import Swal from "sweetalert2";
import AddRecordToReadyForDispatch from "./AddRecordFromReadyForDispatch";

export const ReadyForDispatch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleConfirmation = (document) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action is not revertable",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, dispatch!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        // Update the dispatch status to false
        const dispatchData = {
          dispatch: true,
        };

        updateDispatchDocumentStatus(
          dispatchData,
          dispatch,
          document.id,
          setLoading
        );
      }
    });
  };

  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [toggle, setToggle] = useState(false);
  const pageSize = 10;

  useEffect(() => {
    getAllDocumentStatusDispatch(dispatch);
  }, [dispatch]);

  const { pending, documents } = useSelector((state) => state.documents);

  const handlePageChange = (page) => {
    setCurrentPage(page.selected);
  };

  const performSearch = () => {
    const lowerCasedSearchTerm = searchTerm.toLowerCase();
    const filteredDocs = documents.filter((document) => {
      const { documentTitle, institution, referenceNumber } = document;
      return (
        documentTitle?.toLowerCase().includes(lowerCasedSearchTerm) ||
        institution?.toLowerCase().includes(lowerCasedSearchTerm) ||
        referenceNumber?.toLowerCase().includes(lowerCasedSearchTerm)
      );
    });

    setFilteredDocuments(filteredDocs);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      performSearch();
    }
  };

  useEffect(() => {
    performSearch();
  }, [searchTerm]);

  if (pending) {
    return <Loading />;
  }
  if (loading) {
    return <Loading />;
  }

  const handleAddRocord = () => {
    setToggle(!toggle);
  };

  const document_id = documents[0]?.id;

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Ready For Dispatch</h4>
          <div>
            <input
              type="search"
              className="search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <div className="search-icon">
              <MagnifyingGlassIcon />
            </div>
          </div>
        </div>
        <div className="card-body">
          <div
            className="table-responsive"
            style={{ scrollbarColor: "blue !important" }}
          >
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable "
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row" className="text-dark">
                    <th className="width80">#</th>
                    <th className="sorting_asc text-dark">TR Number</th>
                    <th className="sorting">Document Title</th>
                    <th className="sorting">Institution</th>
                    <th className="sorting">Category</th>
                    <th className="sorting">Status</th>
                    <th className="sorting">Dispatch</th>
                    <th className="sorting">Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {searchTerm !== ""
                    ? filteredDocuments?.map((document, index) => (
                        <tr className="text-dark" key={index}>
                          <td>
                            <img
                              src={folderIcon}
                              style={{ height: 40 }}
                              alt=""
                            />
                          </td>
                          <td>
                            {document?.TRNo !== null ? document?.TRNo : "N/A"}
                          </td>
                          <td>
                            {document?.documentTitle !== null
                              ? document?.documentTitle
                              : "N/A"}
                          </td>
                          <td>
                            {document?.institution !== null
                              ? document?.institution
                              : "N/A"}
                          </td>
                          <td>
                            {document?.category !== null
                              ? document?.category
                              : "N/A"}
                          </td>
                          <td>
                            {document.status === "approved" ? (
                              <button className="btn btn-success btn-xs">
                                {document.status}
                              </button>
                            ) : document.status === "rejected" ? (
                              <button className="btn btn-primary btn-xs">
                                {document.status}
                              </button>
                            ) : document.status === "assigned" ? (
                              <button className="btn btn-secondary btn-xs text-white">
                                {document.status}
                              </button>
                            ) : document.status === "new" ? (
                              <button className="btn btn-info btn-xs text-white">
                                {document.status}
                              </button>
                            ) : (
                              <button className="btn btn-primary btn-xs text-white">
                                {document.status}
                              </button>
                            )}
                          </td>
                          <td>
                            {document.dispatch ? (
                              <button className="btn btn-success btn-xs">
                                true
                              </button>
                            ) : (
                              <button className="btn btn-primary btn-xs">
                                false
                              </button>
                            )}
                          </td>

                          <td>
                            <div>
                              <Link
                                to={`/view-document/${document.id}`}
                                className="btn shadow btn-xs sharp me-1 text-white"
                                style={{ background: "#4D06A5" }}
                              >
                                <i className="fas fa-eye"></i>
                              </Link>
                              <div
                                className="btn shadow btn-xs sharp me-1 text-white"
                                onClick={() => handleConfirmation(document)}
                                style={{ background: "#4D06A5" }}
                              >
                                <i class="fas fa-share-square"></i>
                              </div>

                              <div
                                className="btn shadow btn-xs sharp me-1 text-white"
                                onClick={() => handleAddRocord()}
                                style={{ background: "#4D06A5" }}
                              >
                                <i class="fas fa-plus"></i>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    : documents?.map((document, index) => (
                        <tr className="text-dark" key={index}>
                          <td>
                            <img
                              src={folderIcon}
                              style={{ height: 40 }}
                              alt=""
                            />
                          </td>
                          <td>
                            {document?.TRNo !== null ? document?.TRNo : "N/A"}
                          </td>
                          <td>
                            {document?.documentTitle !== null
                              ? document?.documentTitle
                              : "N/A"}
                          </td>
                          <td>
                            {document?.institution !== null
                              ? document?.institution
                              : "N/A"}
                          </td>
                          <td>
                            {document?.category !== null
                              ? document?.category
                              : "N/A"}
                          </td>
                          <td>
                            {document.status === "approved" ? (
                              <button className="btn btn-success btn-xs">
                                {document.status}
                              </button>
                            ) : document.status === "rejected" ? (
                              <button className="btn btn-primary btn-xs">
                                {document.status}
                              </button>
                            ) : document.status === "assigned" ? (
                              <button className="btn btn-secondary btn-xs text-white">
                                {document.status}
                              </button>
                            ) : document.status === "new" ? (
                              <button className="btn btn-info btn-xs text-white">
                                {document.status}
                              </button>
                            ) : (
                              <button className="btn btn-primary btn-xs text-white">
                                {document.status}
                              </button>
                            )}
                          </td>
                          <td>
                            {document.dispatch ? (
                              <button className="btn btn-success btn-xs">
                                true
                              </button>
                            ) : (
                              <button className="btn btn-primary btn-xs">
                                false
                              </button>
                            )}
                          </td>

                          <td>
                            <div>
                              <Link
                                to={`/view-document/${document.id}`}
                                className="btn shadow btn-xs sharp me-1 text-white"
                                style={{ background: "#4D06A5" }}
                              >
                                <i className="fas fa-eye"></i>
                              </Link>
                              <div
                                className="btn shadow btn-xs sharp me-1 text-white"
                                onClick={() => handleConfirmation(document)}
                                style={{ background: "#4D06A5" }}
                              >
                                <i class="fas fa-share-square"></i>
                              </div>
                              <div
                                className="btn shadow btn-xs sharp me-1 text-white"
                                onClick={() => handleAddRocord()}
                                style={{ background: "#4D06A5" }}
                              >
                                <i class="fas fa-plus"></i>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  {searchTerm !== "" && filteredDocuments.length === 0 && (
                    <tr>
                      <td colSpan="9">
                        <div className="text-center my-3">
                          No results found for the searched item: "{searchTerm}"
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {documents.length > pageSize && (
                <Paginate
                  pageCount={Math.ceil(documents.length / pageSize)}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  previousLabel="<<"
                  nextLabel=">>"
                  breakLabel="..."
                  breakClassName="break-me"
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={3}
                  activeClassName="active"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <AddRecordToReadyForDispatch
        show={toggle}
        close={handleAddRocord}
        document_id={document_id}
        document={documents[0]}
      />
    </div>
  );
};
