// reducers/singleLetterSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  letter: {},
  pending: false,
};

const singleLetterSlice = createSlice({
  name: "singleLetter",
  initialState,
  reducers: {
    handleSingleLetterRequest: (state) => {
      state.pending = true;
    },
    getSingleLetterSuccess: (state, action) => {
      state.letter = action.payload;
      state.pending = false;
    },
    handleSingleLetterError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
    addCommentSuccess: (state, action) => {
      state.letter.letterComments.unshift(action.payload);
    },
    deleteCommentSuccess: (state, action) => {
      const commentIndex = state.letter.letterComments.findIndex(
        (comment) => comment.id === action.payload
      );

      if (commentIndex !== -1) {
        state.letter.letterComments.splice(commentIndex, 1);
      }
    },
  },
});

export const {
  handleSingleLetterRequest,
  getSingleLetterSuccess,
  addCommentSuccess,
  deleteCommentSuccess,
  handleSingleLetterError,
} = singleLetterSlice.actions;

export default singleLetterSlice.reducer;
