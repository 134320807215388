import "./folder.scss";
import { FolderIcon } from "./icons/icons";


const Folder = ({ name, onClick, folderName}) => {
  
  return (
    <li className="folder" onDoubleClick={onClick}>
      <div
        className="folder__icon"
      >
        <FolderIcon />
      </div>
      <div
        className="text-sm break-words text-center">
        {name || folderName}

      </div>
    </li>
  );
};

export default Folder;
