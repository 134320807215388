import { createSlice } from "@reduxjs/toolkit";

export const auditTrailSlice = createSlice({
  name: "tenant",
  initialState: {
    auditTrails: [],
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleAuditTrailsRequest: (state) => {
      state.pending = true;
    },
    createAuditTrailsSuccess: (state) => {
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },
    getAuditTrailsSuccess: (state, action) => {
      state.pending = false;
      state.auditTrails = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    handleAuditTrailsError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
  },
});

export const {
  handleAuditTrailsRequest,
  createAuditTrailsSuccess,
  getAuditTrailsSuccess,
  handleAuditTrailsError,
} = auditTrailSlice.actions;
export default auditTrailSlice.reducer;
