import Avatar from "../ChatList/Avatar";
import moment from "moment";
import chatImage from "../../../../images/avatar/profile_pic.png";

const ChatItem = ({ user, createdAt, msg, image, name }) => {
  const currentDate = new Date();
  const startDate = moment(currentDate);
  const endDate = moment(createdAt);
  const diff = startDate.diff(endDate);
  const diffDuration = moment.duration(diff);
  const diffInMinutes = diffDuration.minutes();

  const firstLetter = name?.charAt(0).toUpperCase();
  const l = name?.split(" ");
  const lastLetter = l[l.length - 1].charAt(0).toUpperCase();

  return (
    <div style={{ animationDelay: `0.8s` }} className={`chat__item ${user}`}>
      <div className="chat__item__content">
        <div className="chat__msg">{msg}</div>
        <div className="chat__meta">
          <span>{diffInMinutes} mins ago</span>
        </div>
        <p style={{ marginBottom: "0", color: "#ccc" }}>{name}</p>
      </div>
      <Avatar
        isOnline="active"
        // image={chatImage}
        fName={firstLetter}
        lName={lastLetter}
      />
    </div>
  );
};

export default ChatItem;
