import { createSlice } from "@reduxjs/toolkit";

export const messageSlice = createSlice({
  name: "messages",
  initialState: {
    messages: [],
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleMessageRequest: (state) => {
      state.pending = true;
    },
    createMessageSuccess: (state) => {
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },
    getMessageSuccess: (state, action) => {
      state.pending = false;
      state.messages = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    handleMessageError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
  },
});

export const {
  handleMessageRequest,
  createMessageSuccess,
  getMessageSuccess,
  handleMessageError,
} = messageSlice.actions;
export default messageSlice.reducer;
