import React, { useState, useRef, useEffect } from "react";
import "./chatContent.scss";
import Avatar from "../ChatList/Avatar";
import ChatItem from "./ChatItem";
import io from "socket.io-client";
import axios from "axios";
import chatImage from "../../../../images/avatar/profile_pic.png";
import { useSelector } from "react-redux";

const socket = io.connect("http://localhost:5000/chat");

export default function ChatContent() {
  const { auth } = useSelector((state) => state.auth);
  const messagesEndRef = useRef(null);
  const [chat, setChat] = useState([]);
  const [message, setMessage] = useState("");

  // useEffect(() => {
  //   // Join the room with ID
  //   socket.emit("joinRoom", "room-1");

  //   // Listen for the chat event from server
  //   socket.on("chat", (msg) => {
  //     setChat([...chat, { content: msg.message }]);
  //   });

  //   window.addEventListener("keydown", handleKeyDown);
  //   scrollToBottom();
  //   return () => window.removeEventListener("keydown", handleKeyDown);
  // }, [chat]);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   // Emit message to server
  //   socket.emit("chat", { message });
  //   console.log(message);

  //   setChat([...chat, { content: message }]);

  //   // socket.on("chat", (payload) => {
  //   //   // chat.push({ content: payload.msg });
  //   //   console.log("first");
  //   //   setChat([...chat, { content: payload.message }]);
  //   // });

  //   setMessage("");
  // };

  // const handleKeyDown = (e) => {
  //   if (e.keyCode === 13) {
  //     if (message !== "") {
  //       const newChat = [...chat];
  //       newChat.push({
  //         key: 1,
  //         type: "",
  //         message: message,
  //         image:
  //           "https://pbs.twimg.com/profile_images/1116431270697766912/-NfnQHvh_400x400.jpg",
  //       });
  //       setChat(newChat);
  //       setMessage("");
  //     }
  //   }
  //   scrollToBottom();
  // };

  const firstLetter = auth?.firstname.charAt(0).toUpperCase();
  const lastLetter = auth?.lastname.charAt(0).toUpperCase();

  useEffect(() => {
    // Join the room with ID
    socket.emit("joinRoom", "room-1");

    // Listen for the chat event from server
    socket.on("chat", (msg) => {
      setChat((prevChat) => [
        ...prevChat,
        {
          content: msg.message,
          senderId: msg.userId,
          userName: msg.userName,
        },
      ]);
    });

    fetchMessage();
    setTimeout(() => scrollToBottom(), 1000);
  }, []); // The dependencies array should be empty so that this effect runs only once on component mount.

  useEffect(() => {
    scrollToBottom();
    setMessage();
  }, [chat]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (message.trim() === "") {
      return; // Prevent sending empty Name
    }

    // Emit message to server
    socket.emit("chat", {
      message: message,
      userId: auth.id,
      userName: `${auth.firstname} ${auth.middlename} ${auth.lastname}`,
    });

    setMessage("");
    scrollToBottom();
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({
      block: "end",
      inline: "nearest",
      behavior: "smooth",
    });
  };

  const onStateChange = (e) => {
    setMessage(e.target.value);
  };

  const fetchMessage = async () => {
    try {
      const { data } = await axios.get("/message");
      setChat(data.data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  return (
    <div className="main__chatcontent">
      <div className="content__header">
        <div className="blocks">
          <div className="current-chatting-user">
            <Avatar isOnline="active" fName={firstLetter} lName={lastLetter} />
            <span className="text-dark">{`${auth.firstname} ${auth.middlename} ${auth.lastname}`}</span>
          </div>
        </div>

        <div className="blocks">
          <div className="settings">
            <button className="btn-nobg">
              <i className="fa fa-cog"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="content__body">
        <div className="chat__items">
          {chat?.length >= 1 &&
            chat?.map((payload, index) => {
              return (
                <ChatItem
                  animationDelay={index + 2}
                  key={payload.key}
                  user={payload.senderId !== auth.id ? "other" : ""}
                  createdAt={payload.createdAt}
                  msg={payload.content}
                  image={payload.image}
                  name={
                    payload.userMessages
                      ? `${payload.userMessages.firstName} ${payload.userMessages.middleName} ${payload.userMessages.lastName}`
                      : payload.userName
                  }
                />
              );
            })}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="content__footer">
        <div className="sendNewMessage">
          <button className="addFiles">
            <i className="fa fa-plus"></i>
          </button>
          <form>
            <input
              type="text"
              placeholder="Type a message here"
              onChange={onStateChange}
              value={message}
              style={{ wordBreak: "break-word" }}
            />
            <button
              className="btnSendMsg"
              id="sendMsgBtn"
              type="submit"
              onClick={handleSubmit}
            >
              <i className="fa fa-paper-plane"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
