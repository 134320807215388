import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPendingCertificates, updatePendingCertificates } from '../../services/PendingCertService';
import { toast } from 'react-toastify';

const initialState = [];

export const retrieveCert = createAsyncThunk(
    "certificate/retrieve",
    async () => {
        const res = await getPendingCertificates();
        console.log(" res from slice",res.data.data)
        return res.data.data;
    }
);
export const updateCert = createAsyncThunk(
    "certificate/update",
    async (id) => {
        const res = await updatePendingCertificates(id)
        toast.success(res.data.message)
    }
)


const pendingCertSlice = createSlice({
    name: "certificate",
    initialState,
   
    extraReducers: (builder) => {
      builder
        
        .addCase(retrieveCert.fulfilled, (_, action) => {
          return action.payload;
        })
        .addCase(updateCert.fulfilled, (state, action) => {
            const updatedId = action.meta.arg;
            return state.map(cert => 
                cert.id === updatedId ? { ...cert, isVerified: true } : cert
            );
        });
    },
  });
  
  export const { getCert } = pendingCertSlice.actions;
  export default pendingCertSlice.reducer;
  