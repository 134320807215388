// useSocket.js
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import { addAssignedDocuments } from "../../redux/Slices/DocumentSlice";

const useSocket = (userId) => {
  const socketRef = useRef(null);
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);

  useEffect(() => {
    socketRef.current = io("http://localhost:5000");
    socketRef.current.on("documentAssigned", (data) => {
      if (data.users.includes(auth.id)) {
        dispatch(addAssignedDocuments(data.updatedDocumentFound));
      }
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [auth.id]);
};

export default useSocket;
