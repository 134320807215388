import http from "../http-common";
import { toast } from "react-toastify";
import {
  getLeaseSuccess,
  getLeaseDataSuccess,
  getLeaseEmailNotificationSuccess,
  handleLeaseError,
  handleLeaseRequest,
} from "../redux/Slices/Leaseslice";

export const addLease = (data, dispatch, history) => {
  dispatch(handleLeaseRequest());
  http
    .post("/lease/", data)
    .then((response) => {
      toast.success(response.data.message);
      history.push(`/manage-lease`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
};

export const getAllLease = (dispatch) => {
  dispatch(handleLeaseRequest());

  http
    .get(`/lease/`)
    .then((response) => {
      dispatch(getLeaseSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleLeaseError);
      toast.error(error.response);
    });
};

export const getLease = async (lease_id) => {
  const data = await http
    .get(`/lease/${lease_id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  return data;
};

export const getSingleLease = async (lease_id) => {
  const data = await http
    .get(`/lease/cal/${lease_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });

  return data;
};

export const updateLease = (data, dispatch, history) => {
  dispatch(handleLeaseRequest());
  http
    .put(`/lease/${data.id}`, data)
    .then((response) => {
      toast.success(response.data.message);
      history.push(`/manage-lease`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
};


export const terminateLease = async (lease_id) => {
	const data = await http
	  .get(`/lease/statusUpdate/${lease_id}`)
	  .then((response) => {
		return response.data;
	  })
	  .catch((error) => {
		return error.response;
	  });
  
	return data;
};
  
export const LeaseExpiringReport = (data, dispatch, history) => {
  dispatch(handleLeaseRequest());
  http
    .post("/lease/leaseExpringReport/", data)
    .then((response) => {
      dispatch(getLeaseDataSuccess(response.data.data));
      toast.success('Lease result data');
    })
    .catch((error) => {
      console.log(error);
      toast.error('Data error');
    });
};

// Get all lease with condition met and send email to corresponding tenants
export const getAllLeaseEmailNotification = (dispatch) => {
  dispatch(handleLeaseRequest());

  http.get('/lease/checkLease').
  then((response) => {
   dispatch(getLeaseEmailNotificationSuccess(response.data.data));
  }).catch((error) => {
    dispatch(handleLeaseError);
    toast.error(error.response);
  })
}



