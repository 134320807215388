import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import {
  updateRecordDocument,
} from "../../../services/documentsService";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "../Backdrop/LoadingSpinner";
import { AddRecordSchema } from "../../../Validations/AddRecordValidations";
import { getProcurementMethod } from "../../../services/procurementMethodService";
import moment from "moment";
import { Modal } from "react-bootstrap";
import LoadingSpinner from "../Backdrop/LoadingSpinner";
import  Select  from 'react-select';
import "./updateRecords.scss"


export default function UpdateRecord({ document_id, data, close, show, doc, document }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  
  useEffect(() => {
    getProcurementMethod(dispatch);
  }, [document_id]);

  console.log("Logging data === ", document);

  const [records, setRecords] = useState({
    documentId: document_id,
    institution: data?.institution,
    dateOfSubmission: moment(data?.dateOfSubmission).format("YYYY-MM-DD"),
    currencyType: data?.currencyType,
    currencyAmount: data?.currencyAmount,
    totalInDalasis: data?.totalInDalasis,
    initialEst: data?.initialEst,
    approvedAmount:  data?.approvedAmount,
    approvedDate:  moment(data?.approvedDate).format("YYYY-MM-DD"),
    remarks: data?.remarks,
    
    suppliers: document?.files?.map((file) => ({
      supplier: file?.fileName || '',
      supplierId: file?.id || '',
      gender: file?.gender || '',
      awarded: Array.isArray(JSON.parse(file?.awarded)) ? JSON.parse(file?.awarded) :   [],
    })) || [],
   

  });
  console.log(records)


  if (loading) {
    return <Loading />;
  }


  const  lotOptions = document?.parent?.lots?.map((lot)=> ({
    label: lot?.name,
    value: lot?.id
  }))





  const supplierOptions = document?.files?.map((supplier) => ({
    label: supplier?.fileName,
    value: supplier?.fileName,
    id: supplier?.id,
  }));

 
  const handleSupplierChange = (selectedOption, index) => {
    const updatedSuppliers = [...selectedSuppliers];
    updatedSuppliers[index] = selectedOption;
    setSelectedSuppliers(updatedSuppliers);
  };
  
  
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "15px",
      padding: "3px",
    }),
  };

  const pushToSupplier = () => {
    setRecords({
      ...records,
      suppliers: [
        ...records.suppliers,
        { supplier: '', gender: '', awarded: [] }
      ],
    });
  }

  const removeSupplier = (index) => {
    const updatedSuppliers = [...records.suppliers];
    updatedSuppliers.splice(index, 1);

    setRecords({
      ...records,
      suppliers: updatedSuppliers,
    });
  };

  return (
    <Modal className="fade"  size="xl" show={show} onHide={() => close()}>
      <Modal.Header>
        <Modal.Title>
          <h3>Update Record</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body  className="update-recode-modal">
        {
        loading ? 
        (<LoadingSpinner/>): !document?.files ? <p>No Files for this document</p> :
        (
          <Formik
            initialValues={records}
            validationSchema={AddRecordSchema}
            onSubmit={async (values) => {
              try {
                console.log("Data to be sent:", 
                {
                  ...values,
                  suppliers: records.suppliers,
                  awarded: records.awarded
                },);
                  
                updateRecordDocument({
                  ...values,
                  suppliers: records.suppliers,
                  awarded: records.awarded
                }, dispatch, history, data?.id);
                setLoading(true);
                close();
                setLoading(false);
                close()
              } catch (error) {
                console.error(error.message);
                setLoading(false);
              }
            }}
          >
            {(formik) => {
              const {
                errors,
                touched,
                isValid,
                dirty,
                handleChange,
                handleBlur,
                values,
              } = formik;
  
              return (
                <Form style={{ marginTop: "10px" }}>
                  <div className="row">
                    <div className="col-xl-12 col-lg-12">
                      <div className="basic-form">
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                          <div className="form-group mb-3 col-md-6">
                           
                            <div className="form-control-sm">
                              <label htmlFor="" className="text-dark">
                                Currency Type
                              </label>
                              <Field
                                type="text"
                                as="select"
                                name="currencyType"
                                id="currencyType"
                                defaultValue={records.currencyType}
                                className={
                                  errors.currencyAmount && touched.currencyAmount
                                    ? "input-error form-control form-control-sm"
                                    : "form-control form-control-sm"
                                }
                                onChange={handleChange}
                              >
                                <option value="">Select current type</option>
                                <option value="dalasi">Dalasis</option>
                                <option value="dollar">Dollar</option>
                                <option value="euro">Euro</option>
                                <option value="pound">Pound</option>
                                <option value="cfa">CFA</option>
                              </Field>
                              <ErrorMessage
                                name="currencyType"
                                component="span"
                                className="text-danger"
                              />
                            </div>
                            <div className="form-control-sm">
                              <label htmlFor="" className="text-dark">
                                Currency Amount
                              </label>
                              <Field
                                type="text"
                                name="currencyAmount"
                                id="currencyAmount"
                                className={
                                  errors.currencyAmount && touched.currencyAmount
                                    ? "input-error form-control form-control-sm"
                                    : "form-control form-control-sm"
                                }
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="currencyAmount"
                                component="span"
                                className="text-danger"
                              />
                            </div>
                            <div className="form-control-sm">
                              <label htmlFor="" className="text-dark">
                                Total in Dalasis
                              </label>
                              <Field
                                type="text"
                                name="totalInDalasis"
                                id="totalInDalasis"
                                // defaultValue={records.totalInDalasis}
                                className={
                                  errors.totalInDalasis && touched.totalInDalasis
                                    ? "input-error form-control form-control-sm"
                                    : "form-control form-control-sm"
                                }
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="totalInDalasis"
                                component="span"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <div className="form-control-sm">
                              <label htmlFor="" className="text-dark">
                                Initial Est
                              </label>
                              <Field
                                type="text"
                                name="initialEst"
                                id="initialEst"
                                // defaultValue={records.initialEst}
                                className={
                                  errors.initialEst && touched.initialEst
                                    ? "input-error form-control form-control-sm"
                                    : "form-control form-control-sm"
                                }
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="initialEst"
                                component="span"
                                className="text-danger"
                              />
                            </div>
                            <div className="form-control-sm">
                              <label htmlFor="" className="text-dark">
                                Approved Amount
                              </label>
                              <Field
                                type="text"
                                name="approvedAmount"
                                id="approvedAmount"
                                className={
                                  errors.approvedAmount && touched.approvedAmount
                                    ? "input-error form-control form-control-sm"
                                    : "form-control form-control-sm"
                                }
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="approvedAmount"
                                component="span"
                                className="text-danger"
                              />
                            </div>
                            <div className="form-control-sm">
                              <label htmlFor="" className="text-dark">
                                Approved Date
                              </label>
                              <Field
                                type="date"
                                name="approvedDate"
                                id="approvedDate"
                                // defaultValue={records?.approvedDate}
                                className={
                                  errors.approvedDate && touched.approvedDate
                                    ? "input-error form-control form-control-sm"
                                    : "form-control form-control-sm"
                                }
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="approvedDate"
                                component="span"
                                className="text-danger"
                              />
                            </div>
                          </div>                                            
                        </div>
                        <div className="form-group mb-3 col-lg-12">
                            {document.category === "bid evaluation" &&
                            (
                              <FieldArray name="suppliers">
                              {({ push, remove }) => (                              
                                  <div className="col-xl-12 col-lg-12">
                                    <div className= ""
                                    >
                                      <div className="">
                                        <div className="features">
                                          <div className="lots">
                                            <div
                                              className="btn btn-sm mt-3 text-white "
                                              style={{ background: "#4D06A5" }}
                                              onClick={() => pushToSupplier()}
                                            >
                                              Add Supplier
                                            </div>
                                          </div>
                                          { records.suppliers?.map((value, index) => {
                                            return (
                                              <div key={index} className="lots-container">
                                                <div className="form-group mb-8">
                                                  
                                                <div className="form-container" style ={{marginTop: "20px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                                
                                                  <div className="form-group col-md-4">
                                                    <label className="form-control-label">Supplier Name</label>
                                                    <Select
                                                      options={supplierOptions}
                                                      value={{
                                                        label: value.supplier,
                                                        value: value.supplier
                                                      }}
                                                      styles={customStyles}
                                                      onChange={(selectedOption) => {
                                                        console.log(selectedOption, "dhhshd")
                                                        handleSupplierChange(selectedOption, index);
                                                        setRecords({
                                                          ...records,
                                                          suppliers: records.suppliers.map((supp, i) => i !== index ? supp :  ({
                                                            ...supp,
                                                            supplier:  selectedOption?.label,
                                                            supplierId:  selectedOption?.label
                                                          }))
                                                        })
                                                      }}
                                                      name={`suppliers[${index}].supplier`}
                                                    />
                                                    
                                                    <ErrorMessage
                                                      name={`suppliers[${index}].supplier`}
                                                      component="span"
                                                      className="text-danger"
                                                      />
                                                  </div>
                                                  
                                                  <div className="form-group col-md-3 ">
                                                    <label htmlFor="features" className="form-control-label">
                                                      Gender
                                                    </label>
                                                    <Field as = "select"
                                                      type="text"
                                                      placeholder="0"
                                                      name={`suppliers[${index}].gender`}
                                                      className="form-control form-select form-control-sm"
                                                      
                                                    >
                                                      <option>select Gender </option>
                                                      <option value="male">Male</option>
                                                      <option value="female">Female</option>
                                                    </Field>
                                                    <ErrorMessage
                                                      name={`suppliers[${index}].gender`}
                                                      component="span"
                                                      className="text-danger"
                                                      />
                                                    
                                                  </div>
                                                  <div className="form-group col-md-3">
                                                      <label htmlFor="features" className="form-control-label">
                                                        Awarded Lots
                                                      </label>
                                                      
                                                        <Select
                                                          isMulti
                                                          name={`suppliers[${index}].awarded`}
                                                          options={lotOptions}
                                                           value={value?.awarded?.map(award => ({
                                                              label: award,
                                                              value: award
                                                           })) || []}
                                                          className="basic-multi-select"
                                                          classNamePrefix="select"
                                                          styles={customStyles}
                                                          onChange={(selectedOptions) => {
                                                            setRecords({
                                                              ...records,
                                                              suppliers: records.suppliers.map((supp, i) => i !== index ? supp :  ({
                                                                ...supp,
                                                                awarded: selectedOptions.map(option => option.label)
                                                              }))
                                                            })
                                                           
                                                          }}
                                                        />
                                                      <ErrorMessage
                                                        name={`supplier[${index}].awarded`}
                                                        component="span"
                                                        className="text-danger"
                                                      />
                                                    </div>
                                                  <div style={{display: "flex", justifyContent: "flex-end", cursor: "pointer"}}>
                                                {index >= 0 && (
                                                  <span 
                                                    onClick={() => removeSupplier()}
                                                    style={{marginLeft: "auto", color: "red", marginTop: "20px"}}>
                                                    <i className="fas fa-trash"></i>
                                                  </span>
                                                ) 

                                                }
                                                
                                                </div>
                                                  <div>
                                                    
                                                  </div>
                                                </div>
                                                </div>
                                              </div>
                                            );
                                          })}

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              )}
                              </FieldArray>
                            )
                            }

                        </div>
                        <div className="form-group mb-3 col-md-12">
                          <label htmlFor="" className="text-dark">
                            Remarks
                          </label>
                          <textarea
                            name="remarks"
                            id="remarks"
                            className="form-control text-dark"
                            defaultValue={records.remarks}
                            onChange={handleChange}
                            cols="30"
                            rows="8"
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className={
                              !(dirty && isValid)
                                ? " btn btn-sm mt-3  disabled-btn text-white"
                                : "btn btn-sm mt-3 text-white "
                            }
                            style={{ background: "#4D06A5" }}
                            disabled={!(dirty && isValid)}
                          >
                            update Record
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )  
        }
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
   
  );
}
