import { createSlice } from "@reduxjs/toolkit";

export const letterSlice = createSlice({
  name: "letters",
  initialState: {
    letters: [],
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleLetterRequest: (state) => {
      state.pending = true;
    },
    createLetterSuccess: (state) => {
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },
    getLetterSuccess: (state, action) => {
      state.pending = false;
      state.letters = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    handleLetterError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
  },
});

export const {
  handleLetterRequest,
  createLetterSuccess,
  getLetterSuccess,
  handleLetterError,
} = letterSlice.actions;
export default letterSlice.reducer;
