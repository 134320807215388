import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./Slices/Auth";
import auditReducer from "./Slices/AuditTrailSlice";
import userReducer from "./Slices/UserManagementSlice";
import clientReducer from "./Slices/ClientManagementSlice";
import documentSlice from "./Slices/DocumentSlice";
import procurementMethods from "./Slices/ProcurementMethodSlice";
import commentReducer from "./Slices/CommentSlice";
import letterReducer from "./Slices/LetterSlice";
import messageSlice from "./Slices/messageSlice";
import procurementReducer from "./Slices/ProcurementsSlice";
import breadcrumbReducer from "./Slices/BreadCrumbSlice";
import singleLetterSlice from "./Slices/singleLetterSlice";
import SystemSlice from "./Slices/SystemSlice";
import SingleDocumentSlice from "./Slices/SingleDocumentSlice";
import BusinessRegistrationSlice from "./Slices/BusinessRegistrationSlice";
import TariffSlice from "./Slices/TariffSlice";
import pendingCertReducer from "./Slices/PendingCertificateSlice"

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: userReducer,
    clients: clientReducer,
    auditTrails: auditReducer,
    documents: documentSlice,
    comments: commentReducer,
    letters: letterReducer,
    procurementMethods: procurementMethods,
    message: messageSlice,
    procurements: procurementReducer,
    breadcrumb: breadcrumbReducer,
    singleLetter: singleLetterSlice,
    system: SystemSlice,
    singleDocument: SingleDocumentSlice,
    businessRegistration: BusinessRegistrationSlice,
    tariffs: TariffSlice,
    pendingCert: pendingCertReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
