import * as Yup from "yup";

export const AddRecordSchema = Yup.object().shape({
  // institution: Yup.string().required("Institution is Required"),
  // project: Yup.string().required("Project is Required"),
  // nameOfSupplier: Yup.string().required("Name of supplier is Required"),
  // justificationForMethod: Yup.string().required("Justification for method is Required"),
  // suppliersGender: Yup.string().required("Suppliers gender is Required"),
  // itemProcured: Yup.string().required("Item procured is Required"),
  // proMethod: Yup.string().required("Pro Method is Required"),
  // dateOfSubmission: Yup.string().required("Date of submission is Required"),
  // currencyType: Yup.string().required("cfa is Required"),
  // currencyAmount: Yup.number().required("cfa is Required"),
  // totalInDalasis: Yup.string().required("Total in Dalasis is Required"),
  // approveAmount: Yup.string().required("Approved Amount is Required"),
  // approveDate: Yup.string().required("Approved Date is Required"),
});
