import React from "react";
import { Card } from "react-bootstrap";

const ServerErrorComponent = () => {
  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <div style={{ width: 500 }}>
        <div className="flex flex-col gap-3">
          <h2 className="font-bold flex items-end gap-2 text-center">
            <span className="text-bg-gray-400">500</span>{" "}
            <span className="text-pink-800">Server Error</span>
          </h2>
          <p>Oops, something went wrong</p>

          <p>
            Check your internet and try to refresh this page or feel free to
            contact us if the problem persist
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServerErrorComponent;
