import { lazy, Suspense, useEffect } from "react";
// import jwtDecode from "jwt-decode";
/// Components
import Admin from "./jsx/admin"; // admin dashboard
import Records from "./jsx/records"; // records dashboard
import Director from "./jsx/director"; // director dashboard
import SeniorManagement from "./jsx/seniorManagement"; // senior management dashboard
import ComplianceOffice from "./jsx/complianceOffice"; // compiance office dashboard

import { useDispatch, useSelector } from "react-redux";
import {
  Router,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { verifyUser } from "./services/AuthService";
import jwtDecode from "jwt-decode";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/tailwind.css";
import { logout } from "./redux/Slices/Auth";
import { store } from "./redux/store";
import CheckAuthorization from "./jsx/components/Security/CheckAuthorization";
import ServerErrorComponent from "./jsx/components/generic/ServerErrorComponent";
import { ViewCertificate } from "./jsx/pages/ViewCertificate";

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

export default function App(props) {
  
  const { isAuthenticated, auth } = useSelector((state) => state.auth);
  const history = useHistory();
  const isViewCertificateRoute = history.location.pathname.startsWith("/view-certificate/");

  console.log(isAuthenticated)

  let verify = 1;
  async function verifyToken() {
      verifyUser(store.dispatch, history, verify);
  }
  const { isServerError } = useSelector((state) => state.system);

  useEffect(() => {
    if(!isViewCertificateRoute) {
      verifyToken();
    }
    console.log("tokk");
  }, []);

  let routes = (
    <>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/view-certificate/:id" component={ViewCertificate}/>
        <Route path="/check-authorization" component={CheckAuthorization} />
        <Route path="/page-register" component={SignUp} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/check-authorization" component={CheckAuthorization} />
      </Switch>
    </>
  );

  if (isServerError) return <ServerErrorComponent />;
  else if (isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Admin />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  }
}
