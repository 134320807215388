import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { getDocumentChartsData } from "../../../services/documentsService";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Backdrop/LoadingSpinner";
import { LoadingOutlined } from "@ant-design/icons";

export default function DashboardPieChart() {
  const dispatch = useDispatch();
  const[loading, setLoading] = useState(false);
  useEffect(() => {
    getDocumentChartsData(dispatch);
  }, []);

  const { pending, documentsChartData } = useSelector(
    (state) => state.documents
  );

  const [data, setData] = useState([["Admin", "Hours per Day"]]);

  useEffect(() => {
    if (!documentsChartData) return false;
   
    setData((prev) => [
      ...prev,
      ["Records", documentsChartData?.count?.records],
      ["Director", documentsChartData?.count?.director],
      ["Compliance Officer", documentsChartData?.count?.compliance],
      ["Senior Management", documentsChartData?.count?.["senior-management"]],
    ]);
    
    return () => {
      setData([]);
    };
  }, [documentsChartData]);

  const options = {
    title: "My Daily Activities",
    is3D: true,
  };

  return (
    <Chart
      chartType="PieChart"
      data={pending  ? <LoadingOutlined /> : data}
      options={options}
      width={"100%"}
      height={"280px"}
      style={{borderBottomLeftRadius: '4px', overflow: 'hidden'}}
    />
  );
}
