import { createSlice } from "@reduxjs/toolkit";

export const leaseSlice = createSlice({
	name: "lease",
	initialState: {
		leased: [],
		leaseData: null,
		emailNotificationData: null,
		pending: false,
		error: false,
		errorMsg: "",
	},

	reducers: {
		handleLeaseRequest: state => {
			state.pending = true;
		},
		createLeaseSuccess: state => {
			state.pending = false;
			state.error = false;
			state.errorMsg = "";
		},
		getLeaseSuccess: (state, action) => {
			state.pending = false;
			state.leased = action.payload;
			state.error = false;
			state.errorMsg = "";
		},
		getLeaseDataSuccess: (state, action) => {
			state.pending = false;
			state.leaseData = action.payload;
			state.error = false;
			state.errorMsg = "";
		},
		getLeaseEmailNotificationSuccess: (state, action) => {
			state.pending = false;
			state.emailNotificationData = action.payload;
			state.error= false;
			state.errorMsg = "";
		},
		handleLeaseError: (state, action) => {
			state.error = true;
			state.pending = false;
			state.errorMsg = action.payload;
		},
	},
});

export const { handleLeaseRequest, createLeaseSuccess, getLeaseSuccess, getLeaseDataSuccess, getLeaseEmailNotificationSuccess, handleLeaseError } =
	leaseSlice.actions;
export default leaseSlice.reducer;
