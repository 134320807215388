import React, { Fragment, useEffect, useState } from "react";

import PageTitle from "../../layouts/PageTitle";
import { Row, Col, Card, Tab, Nav, ListGroup } from "react-bootstrap";
import DocumentPDFViewer from "./DocumentPDFViewer";
import axios from "axios";
import { useParams } from "react-router-dom";
import ManagementDocumentData from "./ManagementDocumentData";
import {
  getSingleDocument,
  getSinglePDFDocument,
} from "../../../services/documentsService";
import moment from "moment";
import LoadingSpinner from "../Backdrop/LoadingSpinner";
// import AllUnits from "./AllUnits";

const ViewDocument = () => {
  const { document_id } = useParams();
  const [document, setDocument] = useState(null);
  const [recordData, setRecordData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchSingleDocument = async () => {
    try {
      setLoading(true);
      const response = await getSingleDocument(document_id);
      setDocument(response?.data);
      setRecordData(response?.data.documentRecords);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSinglePDFDocument = async () => {
    try {
      setLoading(true);
      const response = await getSinglePDFDocument(document_id);
      setData(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSingleDocument();
    fetchSinglePDFDocument();
  }, [document_id]);

  const AllDetails = () => {
    return (
      <>
        <div className="basic-list-group">
          <h4 style={{ marginBottom: "20px" }}>All Record Documents</h4>
          <ListGroup as="ul">
            <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Reference Number:{" "}
              </h6>
              <span className="text-dark" style={{ marginTop: "-8px" }}>
                {document !== null && document.referenceNumber}
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Document Title:{" "}
              </h6>
              <span className="text-dark" style={{ marginTop: "-8px" }}>
                {document !== null && document.documentTitle}
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Institution:{" "}
              </h6>
              <span className="text-dark" style={{ marginTop: "-8px" }}>
                {document !== null && document.institution}
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Upload Date:{" "}
              </h6>
              <span className="text-dark" style={{ marginTop: "-8px" }}>
                {document !== null &&
                  moment(document.uploadDate).format("MM/DD/YYYY")}
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h6 style={{ margin: "0", padding: "0 10px 10px 0" }}>
                Uploaded By:{" "}
              </h6>
              <span className="text-dark" style={{ marginTop: "-8px" }}>
                {document !== null && document.uploadedBy}
              </span>
            </div>
          </ListGroup>
        </div>
        <ManagementDocumentData data={recordData} document_id={document_id} />
      </>
    );
  };

  const AllComments = () => {
    return (
      <>
      {
        document && Object.keys(document.comments)?.length >= 1 ?
        document?.comments?.map((comment, index) => {
          return (
            <div key={index} className='text-dark px-5 py-2' style={{background: '#F7F7F7', borderRadius: '4px', borderTopLeftRadius: '20px', borderBottomRightRadius: '20px', margin: '20px 0', display: 'flex', flexFlow: 'column'}}>
              <h4 className="text-dark">{comment.comment}</h4>
              <span>{moment(comment.updatedAt).format("MM/DD/YYYY")}</span>
            </div>
          )
        }) : <h3 className="text-dark">no comments</h3>
      }
      </>
      
    )
  }

  const tabData = [
    {
      name: "Details",
      content: <AllDetails />,
    },
    {
      name: "Comments",
      content: <AllComments />,
    },
  ];

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Fragment>
      <PageTitle
        activeMenu="View Documents"
        motherMenu="Records"
        pageContent="View Documents"
      />
      <Row>
        <Col xl={4}>
          <Card>
            <Card.Body>
              {/* <!-- Nav tabs --> */}
              <div className="default-tab">
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as="ul" className="nav-tabs">
                    {tabData.map((data, i) => (
                      <Nav.Item as="li" key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`la la-${data.icon} me-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className="pt-4">
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        <p className="text-dark">{data.content}</p>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={8}>
          <Card>
            <Card.Body>
              {data !== null && data !== undefined ? (
                <DocumentPDFViewer data={data} />
              ) : (
                <h3>No document</h3>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ViewDocument;
